import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import './App.css';
import Login from './components/Login';
import MarketListScreen from './components/CryptoMarketList';
import MarketDataScreen from './components/CryptoMarketData';
import ContestsAdmin from './components/ContestsAdmin';

import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import CustomTopBar from './components/globals/CustomTopBar';
import CustomBottomBar from './components/globals/CustomBottomBar'

import EntryAdmin from './components/EntryAdmin';
import CryptoListSelect from './components/CryoptoListSelect';
import BasketListScreen from './components/BasketListSelect';
import ConfirmEntry from './components/ConfirmEnty';
import { AuthProvider } from './components/globals/AuthProvider';
import UserProfile from './components/UserProfile';
import ChatGlobal from './components/ChatGlobal';
import LeaderboardScreen from './components/LeaderBoardScreen';
import BasketDetailScreen from './components/BasketDetailScreen';
import PrivacyPolicy from './components/PrivacyPolicy';
import HowToplay from './components/HowToPlay';
import EditUserProfile from './components/EditUserProfile';
import CryoptoListSelectStock from './components/CryoptoListSelectStock';
import BasketListScreenStock from './components/BasketListSelectStock';
import ConfirmEntryStock from './components/ConfirmEntyStock';
import LeaderboardScreenStock from './components/LeaderBoardScreenStock';
import BasketDetailScreenStock from './components/BasketDetailScreenStock';
import WalletScreen from './components/WalletScreen';

import PrivateRoute from './components/globals/PrivateRoute.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CryptoMarketGainerLoser from './components/CryptoMarketGainerLoser';
import CryptoMarketTrending from './components/CryptoMarketTrending';
import MarketTopBar from './components/globals/MarketTopBar';
import CryptoMarketTabs from './components/CyptoMarketTabs';
const auth = getAuth();

// import
function App() {

  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLogin(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <AuthProvider>
    <Router>
     <div className="App">
        <CustomTopBar /> 
        <div className="app-content">
        <Routes>
          <Route path="/" element={<MarketDataScreen />} />

          {/* Parent route for market-related screens */}
          <Route path="/market" element={<CryptoMarketTabs/>}>
            {/* Nested routes for the tabs */}
            <Route index element={<Navigate to="marketlist" />} /> 
            {/* Default to marketlist */}
            <Route path="marketlist" element={<MarketListScreen />} />
            <Route path="markettrending" element={<CryptoMarketTrending />} />
            <Route path="marketgainerloser" element={<CryptoMarketGainerLoser />} />
          </Route>
          {/* <Route path="/market" element={<MarketTopBar />} />

          <Route path="/marketlist" element={<MarketListScreen />} />
          <Route path="/markettrending" element={<CryptoMarketTrending />} />
          <Route path="/marketgainerloser" element={<CryptoMarketGainerLoser />} /> */}

          <Route path="/login" element={< Login/>} />

          {/* <Route path="/" element={<PrivateRoute  auth={isLogin}/>}> */}
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/leaderboard" element={< LeaderboardScreen/>} />
          {/* </Route> */}
          {/* <PrivateRoute path="/leaderboard" element={<LeaderboardScreen />} isLoggedIn={isLogin} /> */}
           {/* <PrivateRoute path="/leaderboard" element={<LeaderboardScreen />} isLoggedIn={isLogin} /> */}
          {/* Redirect unknown routes to login */}
          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
          <Route path="/contests" element={< ContestsAdmin/>} />
          <Route path="/entry" element={< EntryAdmin/>} />
          <Route path="/cyptoselect" element={< CryptoListSelect/>} />
          <Route path="/basketxselect" element={< BasketListScreen/>} />
          <Route path="/confirmenrty" element={< ConfirmEntry/>} />
          <Route path="/profile" element={< UserProfile/>} />
          <Route path="/editprofile" element={< EditUserProfile/>} />
          <Route path="/chatglobal" element={< ChatGlobal/>} />
          {/* <Route path="/leaderboard" element={< LeaderboardScreen/>} /> */}
          <Route path="/basketdetail" element={< BasketDetailScreen/>} />
          
          <Route path="/privacypolicy" element={< PrivacyPolicy/>} />
          <Route path="/howtoplay" element={< HowToplay/>} />
          <Route path="/cyptoselectstock" element={< CryoptoListSelectStock/>} />
          <Route path="/basketselectstock" element={< BasketListScreenStock/>} />
          <Route path="/confirmenrtystock" element={< ConfirmEntryStock/>} />
          <Route path="/leaderboardstock" element={< LeaderboardScreenStock/>} />
          {/* <Route path="/basketdetailstock" element={< BasketDetailScreenStock/>} /> */}
          <Route path="/basketdetailstock/:basket_id_param" element={<BasketDetailScreenStock />} />
          <Route path="/walletscreen" element={< WalletScreen/>} />

          {/* <Route path="/marketData" element={<MarketDataScreen />} /> */}
        </Routes>
        </div>
        
        <CustomBottomBar /> 
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
