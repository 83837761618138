import { useEffect, useState } from 'react';
// import { getFirestore, collection, query, orderBy, limit,where, getDocs } from 'firebase/firestore';

// import '../view/Login.css'
import { useNavigate } from 'react-router-dom';
// import { setItem, getItem, removeItem } from './globals/LocalStorageUtils';
// import { useAuth } from './globals/AuthProvider';
// import { getAuth } from "firebase/auth";
// import { getDatabase, ref, push, set ,onValue} from "firebase/database";
// import LoadingScreen from './globals/LoadingScreen';
import LoadingScreen from './globals/LoadingScreen';
// const db = getFirestore();
// const database = getDatabase();

function PrivacyPolicy() {

    return (
        <div className="app-container">
    
          <div className="app-container">
          <h2 className="textColor12 textCenter bold italic">Privacy Policy</h2>
          <p className=''>Privacy Policy for CryptoClash.live</p>
          <p className='appTextColor14start margin-10 '>Effective Date: July 12, 2024</p>
          <p className='appTextColor14start margin-10'>Last Updated: July 12, 2024</p>
          <p className='appTextColor14start margin-10'>Company Name: MazeMeet Technologies Pvt. Ltd.</p>
          <p className='appTextColor14start margin-10'>Product Name: CryptoClash.live</p>
          <p className='appTextColor14start margin-10 privacy-align appPrimaryColor10start'>1. Introduction</p>
          <p className='appTextColor14start margin-10'>MazeMeet Technologies Pvt. Ltd. ("we", "us", "our") operates the website and mobile application CryptoClash.live ("Service"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal information when you use our Service and the choices you have associated with that information.</p>
          <p className='appTextColor14start margin-10 appPrimaryColor10start'>2. Information We Collect</p>
          <p className='appPrimaryColor14start margin-10'>2.1 Personal Data</p>
          <p className='appTextColor14start margin-10'>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). This may include, but is not limited to:
Username
Email address
Date of birth
State
Government ID such as Aadhaar card, driving license, or voter ID</p>
<p className='appPrimaryColor14start margin-10'>2.2 Device Information</p>
          <p className='appTextColor14start margin-10'>We may collect information about your device, operating system, network, and location. This helps us improve our Service and enhance your user experience. With your permission, we may also collect information about the apps installed on your device.
</p>
<p className='appPrimaryColor14start margin-10'>2.3 Financial Information</p>
          <p className='appTextColor14start margin-10'>For payment purposes, we may collect sensitive personal information, such as:</p>
          <p className='appTextColor14start margin-10'>Cardholder name
Credit/debit card number (in encrypted form) with expiration date
Banking details
Wallet details
This information is necessary to enable you to complete payments on our Platform.
</p>

<p className='appPrimaryColor10start margin-10'>3. Use of Your Information</p>
          <p className='appTextColor14start margin-10'>We use the information we collect for various purposes, including to:
</p>
          <p className='appTextColor14start margin-10'>Operate and maintain our Service
Improve and personalize our Service
Process your registration and manage your account
Facilitate your participation in contests
Communicate with you about updates, offers, and promotions
Ensure the security of our Platform
</p>
          <p className='appPrimaryColor10start margin-10'>4. Disclosure of Your Information</p>
          <p className='appPrimaryColor14start margin-10'>4.1 Affiliates and Group Entities</p>
          <p className='appTextColor14start margin-10'>We may share your information with our affiliates and group entities to provide you with their products and services.
</p>
<p className='appPrimaryColor14start margin-10'>4.2 Third-Party Service Providers</p>
          <p className='appTextColor14start margin-10'>We may engage third-party service providers for data analytics, storage, and other services to help us serve you better. These providers are required to comply with our privacy standards.</p>
<p className='appPrimaryColor14start margin-10'>4.3 Legal Requirements</p>
          <p className='appTextColor14start margin-10'>We may disclose your information to comply with legal obligations, respond to authorised requests, prevent fraud, and ensure the security of our network and services.</p>
<p className='appPrimaryColor14start margin-10'>4.4 Commercial Partners</p>         
          <p className='appTextColor14start margin-10'>We may share anonymised and aggregated data with our commercial partners for marketing and promotional purposes.</p>
          <p className='appPrimaryColor10start margin-10'>5. Cookies</p>
          <p className='appTextColor14start margin-10'>We use cookies to improve the effectiveness and usability of our Platform. Cookies help us understand your preferences and track your activity on our Platform. You can control the use of cookies through your browser settings.</p>
          <p className='appPrimaryColor10start margin-10'>6. Security</p>
<p className='appTextColor14start margin-10'>We take the security of your information seriously and implement appropriate measures to protect it. However, no system is impenetrable, and we cannot guarantee the security of our database. We are not responsible for unauthorised access to your information.
</p>
<p className='appPrimaryColor10start margin-10'>7. Links to Other Websites</p>
<p className='appTextColor14start margin-10'>Our Platform may contain links to other websites. These websites have their own privacy policies, and we are not responsible for their practices. We encourage you to read their privacy policies before providing any information.</p>
<p className='appPrimaryColor10start margin-10'>8. Retention of Data</p>
<p className='appTextColor14start margin-10'>We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, comply with legal obligations, and resolve disputes. When your information is no longer needed, we will delete it or retain it in anonymised form.</p>
<p className='appPrimaryColor10start margin-10'>9. User Rights and Account Management</p>
<p className='appTextColor14start margin-10'>You have the right to:

Access, update, or delete your personal information
Withdraw your consent to data collection
Request temporary suspension or deletion of your account
To exercise these rights, please contact us at the email address provided below.</p>
<p className='appPrimaryColor10start margin-10'>10. Updates to This Privacy Policy
</p>
<p className='appTextColor14start margin-10'>We may update this Privacy Policy periodically to reflect changes in our practices or applicable laws. We will notify you of any significant changes by posting the new Privacy Policy on this page.
</p>
<p className='appPrimaryColor10start margin-10'>11. Contact Us
</p>
<p className='appTextColor14start margin-10'>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
<p className='appTextColor14start margin-10'>Email: team@cryptoclash.live
</p>
<p className='appTextColor14start margin-10'>By using our Platform, you agree to this Privacy Policy and the collection, use, and disclosure of your information as described herein.</p>
{/* <p className='appTextColor14flex margin-10'></p>
<p className='appTextColor14flex margin-10'></p> */}

          </div>
          {/* {loading && <LoadingScreen />} */}
    
          {/* {loading && <Loader loading={loading} text="Loading ranks..." />} */}
        </div>
      );
    
}
  
export default PrivacyPolicy;