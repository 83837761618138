import React, { useState, useEffect } from 'react';
import '../view/CryptoMarketTrending.css'; // Import your CSS file

const CryptoMarketTrending = () => {
  const [loading, setLoading] = useState(false);
  const [cryptoData, setCryptoData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [sortType, setSortType] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]); // State to store selected items

  const fetchCryptoData = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://pro-api.coingecko.com/api/v3/search/trending', {
        headers: {
          'User-Agent': 'tradingclash/1.0',
          'accept': 'application/json',
          'x-cg-pro-api-key': 'CG-1DrGqL1ynh59Hcpoo1fEsfzG',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Extracting required fields from the response
      const extractedData = data.coins.map((item) => ({
        id: item.item.id,
        symbol: item.item.symbol,
        name: item.item.name,
        price: item.item.data.price.toFixed(5),
        image: item.item.thumb,
        rank: item.item.market_cap_rank,
        percentChange24h: item.item.data.price_change_percentage_24h.inr? item.item.data.price_change_percentage_24h.inr.toFixed(3):0,
      }));

      console.log('trending_data',JSON.stringify(data));
      setCryptoData(extractedData);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchCryptoData();
  }, []);

  const sortByCrtyptoFun = (type) => {
    setSortType(type);

    if (type === 0) {
      setCryptoData((prevData) => [...prevData].sort((a, b) => a.rank - b.rank));
    } else if (type === 1) {
      setCryptoData((prevData) => [...prevData].sort((a, b) => b.percentChange - a.percentChange));
    } else if (type === 2) {
      setCryptoData((prevData) => [...prevData].sort((a, b) => b.percentChange24h - a.percentChange24h));
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    fetchCryptoData();
  };

  return (
    <div className="container-trending">
      {/* <div className="refresh-section">
        <button className="refresh-button" onClick={handleRefresh}>
          Refresh Data
        </button>
      </div> */}
      
      {/* <div className="sort-section"> */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
        <span className='appTextColor14-0'>Sort by: </span>
        {/* sort-button */}
        <button className={`submitButtonTopBar app-margin-10 ${sortType === 0 ? 'active' : ''}`} onClick={() => sortByCrtyptoFun(0)}>Rank</button>
        <button className={` submitButtonTopBar ${sortType === 2 ? 'active' : ''}`} onClick={() => sortByCrtyptoFun(2)}>% Change 24hr</button>
      </div>

      {loading ? (
        <div className="loading-indicator">Loading...</div>
      ) : (
        <div className="crypto-list-trending">
          {cryptoData.map((item, index) => (
            <div className="crypto-card-trending" key={index}>
              <div className="crypto-rank">#{item.rank}</div>
              <div className="crypto-details">
                <img className="crypto-image-trending" src={item.image} alt={item.name} />
                <div>
                  <div className="crypto-name">{item.name} ({item.symbol.toUpperCase()})</div>
                  <div className="crypto-price">USD {item.price}</div>
                </div>
              </div>
              <div className="crypto-change">
                <div className={`crypto-change-24h ${item.percentChange24h > 0 ? 'positive' : 'negative'}`}>
                  {item.percentChange24h} % 24hr
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CryptoMarketTrending;
