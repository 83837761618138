import { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit,where, getDocs } from 'firebase/firestore';

// import '../view/Login.css'
import { useNavigate } from 'react-router-dom';
import { setItem, getItem, removeItem } from './globals/LocalStorageUtils';
// import { useAuth } from './globals/AuthProvider';
// import { getAuth } from "firebase/auth";
// import { getDatabase, ref, push, set ,onValue} from "firebase/database";
import LoadingScreen from './globals/LoadingScreen';

// const db = getFirestore();
// const database = getDatabase();

function HowToplay() {

    return (
        <div className="app-container">
    
          <div className="app-container" style={{marginTop:30}}>
          {/* <h2 className="textColor12 textCenter bold italic">Privacy Policy</h2> */}
          
          <h3 className='appPrimaryColor10flex' style={{fontSize:20}}>How to play cryptoclash.live ?</h3>
          <p className='appTextColor14flex margin-10'>Step 1: Join a contest by paying a 50 rupee entry fee.</p>
          <p className='appTextColor14flex margin-10'>Step 2: Select four cryptos you believe will increase in value during the 30 min contest period from the TOP 50 cryptos by market cap.</p>
          <p className='appTextColor14flex margin-10'>Step 3: Assign multipliers (1x, 2x, 3x, 4x) to the chosen four cryptos.</p>
          <p className='appTextColor14flex margin-10'>Step 4: Monitor the performance of selected cryptos for over 30 minutes.</p>
          <p className='appTextColor14flex margin-10'>Step 5: Winners are determined based on the highest points accumulated.</p>
          {/* <p></p>
          <p></p> */}

          </div>
          {/* {loading && <LoadingScreen />} */}
    
          {/* {loading && <Loader loading={loading} text="Loading ranks..." />} */}
        </div>
      );
}
  
export default HowToplay;