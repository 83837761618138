import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import '../../view/TopBar.css'
// import logo from '../../assets/applogo.png'; // Assuming the logo image is in this path
// import { getAuth } from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ReactComponent as Applogo} from '../../assets/applogo.svg';
import { ReactComponent as Profile} from '../../assets/user.svg';
import { ReactComponent as Wallet} from '../../assets/wallet.svg';


const TopBar = () => {
  const auth = getAuth();

  // const authUser = auth.currentUser;
  const [isLogin, setLogin] = useState(false);
  const [user , setUser] = useState(null);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLogin(!!user);
    });
    console.log("check_login",isLogin);

    return () => unsubscribe();
  }, [auth]);
  
  return (
    <div 
    className="app-rowView"
    // className="topbar"
    >
      {/* <nav> */}
      {/* <div className=" app-rowView "> */}
      <div className="">
            <Link to="/" className="appPrimaryColor10start">
            <div>
          <Applogo 
          // className="logo"
           style={{ marginLeft:"20px" , width: '40px', height: '40px' }}
            />
             {/* cryptoclash.live */}
          </div>
            {/* <img src={logo} alt="Logo" className="logo" /> */}
             </Link>
      </div>

       <div className="appPrimaryColor10end">
       {!isLogin && 
                //  <NavLink to="/chatglobal" activeClassName="active">
                //   Login
                //   </NavLink>

          <Link className="submitButtonTopBar appPrimaryColor10end" to="/login">Login</Link>
       } 
       {/* {isLogin && user && user!=null && */}
       {isLogin &&
       <div
       className="app-rowView"
        style={{flexDirection:'row'}}>
        <div>
          <Link 
          // className="appPrimaryColor10end" 
          to="/walletscreen">
            <div>
            <Wallet 
             style={{  width: '20px', height: '20px' }}
            />
            </div>
          </Link> 
        </div> 
        <div>  
          <Link 
          className="appPrimaryColor10end" 
          to="/profile">
            {/* Profile */}
            <div>
          <Profile 
           style={{  width: '20px', height: '20px' }}
            />
          </div>
          </Link>
        </div>
        </div>    
       }
        </div>     
      {/* </div> */}

      {/* </nav> */}
    </div>
  );
};

export default TopBar;
