// LeaderboardScreen.js
import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
import { useLocation,useNavigate } from 'react-router-dom';

// import {
//   collection, getDocs, query, orderBy
// } from "./firebase";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";

// import Loader from "./Loader";
// import BackTopText from "./BackTopText";
// import "./LeaderboardScreen.css";
import LoadingScreen from "./globals/LoadingScreen";

const LeaderboardScreenStock = () => {
  const [loading, setLoading] = useState(false);
  const [baskets, setBaskets] = useState([]);
  const [userBaskets, setUserBaskets] = useState([]);
  const [cryptoTop, setCryptoTop] = useState([]);
  const [cryptoBottom, setCryptoBottom] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  
  // const { c_id } = location.state;

  const db = getFirestore();
  const auth = getAuth();
  const authUser = auth.currentUser;
  const  c_id  = '1';

  useEffect(() => {
    fetchData();
    // fetchCryptoData();
  }, [c_id]);

  const fetchData1 = async () => {
    try {
      setLoading(true);
      const basketsRef = collection(db, 'ActiveMocks', c_id, 'Basket');
      const q = query(basketsRef, orderBy('rank'));

      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    //   const userId = "your_user_id"; // Replace with actual user ID
      const userData = data.filter((item) => item.userid === authUser.uid);

      setBaskets(data);
      setUserBaskets(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
  
      const db = getDatabase();
      // Reference to the 'Basket' data under 'ActiveMocks' for the specific c_id
      const basketsRef = ref(db, `ActiveMocks/${c_id}/Basket`);
      
      // Get the snapshot of the data from the specified path
      const snapshot = await get(basketsRef);
  
      if (snapshot.exists()) {
        const data = [];
  
        snapshot.forEach((childSnapshot) => {
          const basket = {
            id: childSnapshot.key,
            ...childSnapshot.val(),
          };
          data.push(basket);
        });
  
        // Sort the data by rank manually (since Realtime Database doesn't support ordering natively)
        data.sort((a, b) => a.rank - b.rank);
  
        // Filter the data by userid
        if(authUser){
          const userData = data.filter((item) => item.userid === authUser.uid);
          setUserBaskets(userData);

        }
  
        setBaskets(data);
      } else {
        console.log('No data available');
        setBaskets([]);
        setUserBaskets([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  

  const gotoBasketDetailScreen = (item) => {
    const baskType='1';
    const e_id =c_id;
    const basket_id= item.id;
    const basket_id_param= item.id;

    
    navigate(`/basketdetailstock/${basket_id_param}`, { state: { item, e_id, basket_id, baskType } });

    // navigate(`/basketdetail/${item.id}`, { state: { item, c_id } });
    // navigate('/basketdetailstock/'+basket_id, { state: { item,e_id,basket_id,baskType } });
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
    // fetchCryptoData();
  };
  const gotoLeaderboardScreen = async () => {
    navigate('/cyptoselectstock' );
  };
  

  return (
    <div className="app-container">
      <div style={{alignContent:'center',
          display:'flex',
           width:'100%',
           alignItems:'center',
           justifyContent:'center'}}>
          <div 
          className='inputWithPrefix2 appPadding10 center'
          onClick={() => gotoLeaderboardScreen()}
          >
          <p className="appTextColor12 center">Create Portfolio</p>
          </div>
          </div>
      {/* <BackTopText title="Leaderboard" /> */}

      {/* <div className="crypto-container">
        <h2>Top 5 Cryptocurrencies (1 Hour)</h2>
        <div className="crypto-row">
          {cryptoTop.map((crypto, index) => (
            <div key={index} className="crypto-item">
              <img src={crypto.image} alt={crypto.name} className="crypto-image" />
              <p>{crypto.name}</p>
              <p className={crypto.price > 0 ? "crypto-price green" : "crypto-price red"}>Rs {crypto.price}</p>
              <p className={crypto.percentChange > 0 ? "crypto-change green" : "crypto-change red"}>{crypto.percentChange}%</p>
            </div>
          ))}
        </div>

        <h2>Bottom 5 Cryptocurrencies (1 Hour)</h2>
        <div className="crypto-row">
          {cryptoBottom.map((crypto, index) => (
            <div key={index} className="crypto-item">
              <img src={crypto.image} alt={crypto.name} className="crypto-image" />
              <p>{crypto.name}</p>
              <p className={crypto.price > 0 ? "crypto-price green" : "crypto-price red"}>Rs {crypto.price}</p>
              <p className={crypto.percentChange > 0 ? "crypto-change green" : "crypto-change red"}>{crypto.percentChange}%</p>
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className="contest-details">
        <button onClick={gotoBasketDetailScreen}>Contest Details</button>
      </div> */}

      {/* <div className="refresh-container">
        <p>Swipe to refresh (points update every 1 min)</p>
        <button onClick={onRefresh}>Refresh</button>
      </div> */}

      <div className="app-container">
      <h2 className="textColor12 textCenter bold italic">Leaderboard</h2>

        <div className="leaderboard-item">
          <span className="appTextBg10">Rank</span>
          <span className="appTextBg10">All Teams ({baskets.length})</span>
          <span className="appTextBg10">Profit</span>
        </div>

        {userBaskets.length > 0 && (
          <div className="my-teams ">
            <h3 className="appPrimaryColor10flex" >My teams</h3>
            {userBaskets.map((item, index) => (
              <div key={index} className="leaderboard-item" onClick={() => gotoBasketDetailScreen(item)}>
                <span className="appTextColor12flex">#{item.rank}</span>
                <div className=" appTextColor12flex leaderboard-item-details">
                  <img src={item.image || require('../assets/applogo.png')} 
                  alt={item.userName} 
                  className="app-crypto-image" />
                <div className="appTextColor12flex container2 center">    
                  <span className="appTextColor12flex">{item.userName}</span>
                  {/* {item.amountWon  && (
                 <span className="appTextColor12flex appGreen12">Won {item.amountWon.toFixed(2)}</span>
                 )} */}
                </div>
                  {/* <span className="appTextColor12flex">{item.basketNo ? `T ${item.basketNo + 1}` : "T 1"}</span> */}
                </div>
                <div className="appTextColor12flex leaderboard-item-profit">
                  <span className={item.totalProfit > 0 ? "green" : "red"}>{Number(item.totalProfit).toFixed(2)}</span>
                  {/* <span className={item.totalPerChange > 0 ? "green" : "red"}>{item.totalPerChange ? `${item.totalPerChange.toFixed(2)}%` : "0 %"}</span> */}
                </div>
              </div>
            ))}
          </div>
        )}

       <h3 className="appPrimaryColor10flex" style={{marginTop:20}} >All teams</h3>
        {baskets.map((item, index) => (
          <div key={index} className="leaderboard-item " onClick={() => gotoBasketDetailScreen(item)}>
            <span className="appTextColor12flex">#{item.rank}</span>
            <div className="appTextColor12flex leaderboard-item-details">
            <img src={item.image || require('../assets/applogo.png')} 
                  alt={item.userName} 
                  className="app-crypto-image" />
              <div className="appTextColor12flex container2 center">    
              <span className="appTextColor12flex">{item.userName}</span>
              {/* {item.amountWon  && (
                <span className="appTextColor12flex appGreen12">Won {item.amountWon.toFixed(2)}</span>
              )} */}
              </div>
              {/* <span className="appTextColor12flex">{item.basketNo ? `T ${item.basketNo + 1}` : "T 1"}</span> */}
            </div>
            <div className="appTextColor12flex leaderboard-item-profit">
              <span className={item.totalProfit > 0 ? "green" : "red"}>{Number(item.totalProfit).toFixed(2)}</span>
              {/* <span className={item.totalPerChange > 0 ? "green" : "red"}>{item.totalPerChange ? `${item.totalPerChange.toFixed(2)}%` : "0 %"}</span> */}
            </div>
          </div>
        ))}
      </div>
      {loading && <LoadingScreen />}

      {/* {loading && <Loader loading={loading} text="Loading ranks..." />} */}
    </div>
  );
};

export default LeaderboardScreenStock;
