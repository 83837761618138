import React, { useState, useEffect } from "react";
import { getFirestore, collection, query, orderBy, doc, onSnapshot } from 'firebase/firestore';
// import Loader from "./globals/Loader"; // Ensure this component is suitable for React JS
// import './globals/styles.css'; // Import the global styles (adapted for web)
import { getAuth } from "firebase/auth";
import LoadingScreen from "./globals/LoadingScreen";
// Assuming globalStyles and colors are in a CSS file imported above

const WalletScreen = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState('Loading wallet...');
  const [wallet, setWallet] = useState({});
  const [transactions, setTransactions] = useState([]);
  const auth = getAuth();

  const user = auth.currentUser;
  const db = getFirestore();

  useEffect(() => {
    setLoading(true);
    setLoadingTxt('Loading balance...');
    const userId = user.uid;

    const walletRef = doc(db, 'UserWallet', userId);
    const walletUnsubscribe = onSnapshot(walletRef, (doc) => {
      if (doc.exists()) {
        setWallet(doc.data());
      } else {
        console.log('User wallet document does not exist');
        setWallet({});
      }
      setLoading(false);
    });

    setLoading(true);
    setLoadingTxt('Loading transactions...');
    const transactionsRef = collection(db, 'Transactions', userId, 'All');
    const transactionsQuery = query(transactionsRef, orderBy('createdAt', 'desc'));
    const transactionsUnsubscribe = onSnapshot(transactionsQuery, (snapshot) => {
      const transactionsData = [];
      snapshot.forEach((doc) => {
        transactionsData.push({ id: doc.id, ...doc.data() });
      });
      setTransactions(transactionsData);
      setLoading(false);
    });

    return () => {
      walletUnsubscribe();
      transactionsUnsubscribe();
    };
  }, [db, user.uid]);

  

  const showToast = () => {
    alert('Please select 2x and 1.5x crypto to proceed');
  };

  const renderTransText = (item) => {
    let textx = '';
    const type = item.type;
    const amt = item.amount;
    if (type === 0) textx = `You received ${amt} for signup.`;
    else if (type === 1) textx = `You received ${amt} for winning a contest.`;
    else if (type === 2) textx = `Amount ${amt} deducted for joining a contest.`;

    return textx ;
  };

  const renderTime = (time) => {
    const timestamp = time;
    const date = new Date(timestamp);

    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const day = date.getDate();
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate ;
  };

  return (
    <div className="container">
      {/* <div className="safe-area"> */}
        <div className="col center">
          {/* <BackTopText title='Wallet' /> Ensure BackTopText is adapted for web */}

          <div className="col center inputWithPrefix-mycontest app-margin-20"
            >
            <span className="appTextColor12" >Balance</span>
            <strong 
            className="appPrimaryColor12 textCenter bold italic appPadding10"
            >
             {wallet.amount? Number(wallet.amount).toFixed(2): '0' } 
            </strong>
          </div>

          {transactions && (
            <div className="transaction-list">
              <span 
              className="appTextColor18 textCenter bold app-margin-20 "
              >Transactions</span>

              {transactions.map((item, index) => (
                <div 
                // className="card-fit" 
                className=" transaction-list2"
                key={index}>
                  <div  className="appTextColor14 ">
                    {renderTransText(item)}
                  </div>
                  <div className="appTextColor10">
                    {renderTime(item.createdAt)}
                  </div>
                </div>
              ))}
            </div>
          )}
        {/* </div> */}
      </div>

      
      {loading && <LoadingScreen />}

      {/* <LoadingScreen 
      loading={loading} 
       /> */}
    </div>
  );
};

export default WalletScreen;
