import e from 'cors';
import React, { useState, useEffect } from 'react';
// import auth, { FirebaseAuthTypes } from '@react-native-firebase/auth';
// import { useRoute } from '@react-navigation/native';
import { useLocation,useNavigate } from 'react-router-dom';
// import Loader from './globals/Loader';
// import BackTopText from "./globals/BackTopText";
// import { Picker } from '@react-native-picker/picker';
// import './ContestScreen.css'; // Import the global styles and colors
import CustomAlert from './globals/CustomAlert';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import LoadingScreen from './globals/LoadingScreen';
import { getDatabase, ref, get,query as databaseQuery,limitToFirst } from "firebase/database";

const CryptoMarketListStock = ({ navigation }) => {
  const [loading, isLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedValue, setSelectedValue] = useState("java");
  const [sortType, setSortType] = useState(0);
  const [totalAmount, setTotalAmount] = useState(100000);

  const location = useLocation();
  const navigate = useNavigate();
  const db = getFirestore();

  // const {  item, baskType, basket_id } = location.state;
  const  e_id = '1';
  const baskType='3';

//   const route = useRoute();
//   const e_id = route.params.e_id;
//   const item = route.params.item;
//   const baskType = route.params.baskType;
//   const basket_id = route.params.basket_id;

  const fetchCryptoData1 = async () => {
    isLoading(true);
    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=inr&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&x_cg_pro_api_key=CG-1DrGqL1ynh59Hcpoo1fEsfzG',
        {
          headers: {
            'User-Agent': 'tradingclash/1.0',
            'Access-Control-Allow-Origin': '*',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const arr = ['usdt', 'usdc', 'dai'];
      const extractedData = data
        .filter(item => !arr.includes(item.symbol))
        .map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.current_price,
          image: item.image,
          percentChange: item.price_change_percentage_1h_in_currency.toFixed(3),
          rank: item.market_cap_rank,
          percentChange24h: item.price_change_percentage_24h.toFixed(3),
          assignedAmt:0,
        }));

      if (sortType === 0) {
        extractedData.sort((a, b) => a.rank - b.rank);
      } else if (sortType === 1) {
        extractedData.sort((a, b) => b.percentChange - a.percentChange);
      } else if (sortType === 2) {
        extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
      }

      setCryptoData(extractedData);
      isLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
      isLoading(false);
      setRefreshing(false);
    }
  };

  const fetchCryptoData = async () => {
    isLoading(true);
    
    try {
      const db = getDatabase();
      // Reference to the 'CryptoDataCurrent' path in Realtime Database
      const cryptoDataRef = ref(db, 'CryptoDataCurrent');
      // Fetch the data from Realtime Database
      // const snapshot = await get(cryptoDataRef);
  
      const cryptoDataQuery = databaseQuery(cryptoDataRef, limitToFirst(100));
  // Fetch the data from Realtime Database
    const snapshot = await get(cryptoDataQuery);
  
      if (snapshot.exists()) {
        // Convert the snapshot data to an array of objects
        const data = Object.values(snapshot.val());
  
        // Filter and transform the data
        const arr = ['usdt', 'usdc', 'dai'];
        const extractedData = data
          // You can uncomment the filter line if you want to exclude specific items
          .filter(item => !arr.includes(item.symbol))
          .map(item => ({
            id: item.id,
            symbol: item.symbol,
            name: item.name,
            price: item.current_price,
            image: item.image,
            percentChange: Number(item.percentChange1hr).toFixed(3),
            rank: item.market_cap_rank,
            percentChange24h: Number(item.percentChange24h).toFixed(3),
            assignedAmt:0,
          }));
  
        // Sort the data based on the sortType
        if (sortType === 0) {
          extractedData.sort((a, b) => a.rank - b.rank);
        } else if (sortType === 1) {
          extractedData.sort((a, b) => b.percentChange - a.percentChange);
        } else if (sortType === 2) {
          extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
        }
  
        // Set the state with the extracted and sorted data
        setCryptoData(extractedData);
      } else {
        console.log('No crypto data available');
        setCryptoData([]);
      }
    } catch (error) {
      console.error('Error fetching crypto data from Realtime Database:', error.message);
    } finally {
      isLoading(false);
      setRefreshing(false);
    }
  };

  const fetchCryptoData11 = async () => {
    isLoading(true);
    try {
      // Reference to the Firestore collection
      const cryptoDataCollectionRef = collection(db, 'CryptoDataCurrent');
  
      const cryptoDataQuery = query(cryptoDataCollectionRef, orderBy('market_cap_rank', 'asc'));
  
      // Get documents based on the query
      const querySnapshot = await getDocs(cryptoDataQuery);
      // Get all documents from the Firestore collection
      // const querySnapshot = await getDocs(cryptoDataCollectionRef);
      
      // Convert Firestore snapshot to an array of objects
      const data = querySnapshot.docs.map(doc => doc.data());
  
      // Filter and transform the data
      const arr = ['usdt', 'usdc', 'dai'];
      const extractedData = data
        .filter(item => !arr.includes(item.symbol))
        .map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.current_price,
          image: item.image,
          percentChange: Number(item.percentChange1hr).toFixed(3),
          rank: item.market_cap_rank,
          percentChange24h: Number(item.percentChange24h).toFixed(3),
          assignedAmt:0,
        }));
  
      // Sort the data based on the sortType
      if (sortType === 0) {
        extractedData.sort((a, b) => a.rank - b.rank);
      } else if (sortType === 1) {
        extractedData.sort((a, b) => b.percentChange - a.percentChange);
      } else if (sortType === 2) {
        extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
      }
  
      // Set the state with the extracted and sorted data
      setCryptoData(extractedData);
      isLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching crypto data from Firestore:', error.message);
      isLoading(false);
      setRefreshing(false);
    }
  };

  const auth = getAuth();

  const authUser = auth.currentUser;


  useEffect(() => {
    // setLoading(true);
    if (authUser) {
      fetchCryptoData();
    } else {
      navigate('/login');
    }
    // setLoading(false);
    // if (baskType && baskType === "2") {
    //   console.log('b_iddd', '' + JSON.stringify(item.stockList));
    //   setSelectedItems(item.stockList);
    // }
  }, [authUser, navigate]);

  const onRefresh = () => {
    setRefreshing(true);
    fetchCryptoData();
  };

  const gotoBasketScreen = () => {
    if (selectedItems.length < 11 && selectedItems.length>0){
      navigate('/basketselectstock', { state: { selectedItems, totalAmount }});
    }
    
    // else if(totalAmount>1000){
    //   showAlert('Alert!! , Please assign atleast 1000');
    // }
    // else if(totalAmount<10000){
    //   showAlert('Alert!! , YOu can only assign atmost 10000');
    // }
      else
    showAlert('Alert!! , Please select atleast 1 crypto to proceed');
      // showToast();
  };

  const showToast = () => {
    alert('Alert!! , Please select 10 crypto to proceed');
  };

  const setDropIconValue = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      if (selectedItems.length < 10)
        setSelectedItems([...selectedItems, item]);
    }
  };

  const sortByCrtyptoFun = (type) => {
    setSortType(type);
    let sortedData = [...cryptoData];
    if (type === 0) {
      sortedData.sort((a, b) => a.rank - b.rank);
    } else if (type === 1) {
      sortedData.sort((a, b) => b.percentChange - a.percentChange);
    } else if (type === 2) {
      sortedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
    }
    setCryptoData(sortedData);
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const hideAlert = () => {
    setAlertVisible(false);
  };
  
  

  return (
    <div className="container">
      <div className="safe-area">
        {/* <div className="scrollView"> */}
          {/* <BackTopText title='Crypto List' /> */}

          

          <div className="instructions">
            <p>Please select 1 or more cryptocurrencies to make your portfolio</p>
          </div>
          <div className="sortOptions">
            <span className='appTextColor10-0'>sort by: </span>
            <div 
            // className="crypo-select-sort-Item" 
            onClick={() => sortByCrtyptoFun(0)} className={sortType === 0 ? 'crypo-select-sort-Item-select' : 'crypo-select-sort-Item-unselect'}>rank</div>
            <div onClick={() => sortByCrtyptoFun(1)} className={sortType === 1 ? 'crypo-select-sort-Item-select' : 'crypo-select-sort-Item-unselect'}>% change 1hr</div>
            <div onClick={() => sortByCrtyptoFun(2)} className={sortType === 2 ? 'crypo-select-sort-Item-select' : 'crypo-select-sort-Item-unselect'}>% change 24hr</div>
          </div>

          <div
          className="cryptoItem  " 
          // className="cryptoListHeader"
          >
            <span            
            style={{flex:1}}
            className="appTextColor12">sym</span>
            <span
            style={{flex:1}}
            className="appTextColor12">name</span>
            <span
            style={{flex:1}}
            className="appTextColor12">price</span>
            {/* <span
            style={{flex:1}}
            className="appTextColor12">change % 1hr</span> */}
            <span
            style={{flex:1}}
            className="appTextColor12">change % 24hr</span>
          </div>

          <div className=''>
          {cryptoData.map((item, index) => (
            <div
              key={index}
              onClick={() => setDropIconValue(item)}
              className={`cryptoItem ${selectedItems.some(selectedItem => selectedItem.id === item.id) ? 'selected' : ''}`}
            >
              <div 
              style={{flex:1}}
               className="appTextColor12">{item.rank}</div>
              <div className="appTextColor12 center" 
               style={{flex:1}}
               >
                <img src={item.image} alt={item.symbol} className="app-crypto-image" />
                <span className='center'>{item.name}</span>
              </div>
              {/* <div className="appTextColor12"
                   style={{flex:1}}
                   >{item.price}</div> */}
              <div className="appTextColor12"
                   style={{flex:1}} >Rs {item.price}</div>
              <div className="appColumn100 appTextColor10"
                   style={{flex:1}}>
                <span className={item.percentChange > 0 ? 'greenText' : 'redText'}>{item.percentChange} % 1hr</span>
                <span className={item.percentChange24h > 0 ? 'greenText' : 'redText'}>{item.percentChange24h} % 24hr</span>
              </div>
            </div>
          ))}
        </div>
        {/* </div> */}

        <div className=" center app-margin-20 ">
          {/* <div className='button-fixed-top'>
        <p className="textSecondaryBold10 app-margin-20">Enter the total amount </p>
                        <input
                          type="number"
                          // placeholder="Enter name"
                          className="inputTextEdit-profile "
                          value={totalAmount}
                          // value={100000}
                          // onChange={(e) => setTotalAmount(e.target.value)}
                          required
                          disabled
                        />
         </div>                */}
        <div 
        className=" button-fixed inputWithPrefix2 appPadding10"
        // className=" nextButton" 
        onClick={gotoBasketScreen}>Next</div>
      </div>
      </div>

      {alertVisible && <CustomAlert message={alertMessage} onClose={hideAlert} />}
      {loading && <LoadingScreen />}

      {/* {loading && <Loader loading={loading} text="Loading crypto data..." />} */}
    </div>
  );
};

export default CryptoMarketListStock;
