import React, { useState, useEffect } from "react";
// import { ActivityIndicator } from 'react-loader-spinner';
import { getFirestore, collection, doc, getDocs, onSnapshot, query, orderBy, limit }
 from '@firebase/firestore';
 import { useLocation,useNavigate } from 'react-router-dom';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { auth } from './firebase'; // Make sure to initialize Firebase and export auth from your firebase.js file
// import { useParams } from 'react-router-dom'; // React Router for navigation
// import './styles.css'; // Global styles and colors
import { getAuth } from "firebase/auth";
import LoadingScreen from "./globals/LoadingScreen";

const BasketDetailScreen = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState(null);
  const [basketid, setBasketid] = useState(null);
  const [stockTwoX, setStockTwoX] = useState(null);
  const [stockOneHalfX, setStockOneHalfX] = useState(null);
  const db = getFirestore();
//   const [user] = useAuthState(auth);
//   const { baskType, e_id, item, basket_id } = useParams();
   const location = useLocation();
 
   const { item,e_id,basket_id,baskType } = location.state;

  const auth = getAuth();
  const user = auth.currentUser;

  const {
    twoX = null,
    threeX = null,
    fourX = null,
    userid = null,
    totalPoints = null,
  } = item ?? {};

  const [baskets, setBaskets] = useState([]);
  const [twoXs, settwoX] = useState('');
  const [threeXs, setthreeX] = useState('');
  const [fourXs, setfourX] = useState('');
  const [basketNos, setbasketNo] = useState(0);
  const [userids, setuserids] = useState('');
  const [totalPointss, settotalPoints] = useState(0);
  const [totalPerChanges, settotalPerChange] = useState(0);
  const [userD, setUser] = useState({});
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (item) {
      setBaskets(item);
      settwoX(twoX);
      setthreeX(threeX);
      setfourX(fourX);
      setbasketNo(item.basketNo);
      setuserids(item.userid);
      settotalPoints(item.totalPoints);
      settotalPerChange(item.totalPerChange);
      if (item.userid) renderUserDetail(item.userid);
    } else {
      fetchData();
    }
  }, [item]);

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const basketRef = doc(db, 'ActiveNow', e_id, 'Basket', basket_id);
      const docSnapshot = await getDocs(basketRef);

      if (docSnapshot.data) {
        const basketData = { id: docSnapshot.id, ...docSnapshot.data() };
        setBaskets(basketData);
        settwoX(basketData.twoX);
        setthreeX(basketData.threeX);
        setfourX(basketData.fourX);
        setbasketNo(basketData.basketNo);
        setuserids(basketData.userid);
        settotalPoints(basketData.totalPoints);
        settotalPerChange(basketData.totalPerChange);

        if (basketData.userid) renderUserDetail(basketData.userid);
      } else {
        console.log('Basket not found for ID:', basket_id);
      }

      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching basket data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderX = (name, symbol, item11) => {
    let textx = '';
    if (symbol === twoXs) textx = '2X';
    else if (symbol === threeXs) textx = '3X';
    else if (symbol === fourXs) textx = '4X';
    else textx = '1X';

    return <div className="labelsContainer">{textx}</div>;
  };

  const renderUserDetail = async (user_id) => {
    const usersRef = doc(db, 'users', user_id);
    await onSnapshot(usersRef, (doc) => {
      if (doc.exists) {
        setUser(doc.data());
      } else {
        console.log('User wallet document does not exist');
        setUser({});
      }
    });
  };

  const gotoStockScreen = (basket_id, item) => {
    // history.push(`/StocksListScreen/${e_id}/${item}/${baskType}/${basket_id}`);
  };

  return (
    <div className="app-container">
      <div className="scrollView" 
    //   onScroll={onRefresh}
      >
        <div className="app-container">
          {/* <BackTopText title='Basket details' /> */}

          {baskets.stockList && (
            <div>
              <div className="center" style={{marginTop:30}}>
                {/* <div className="cardVerticalSmallStart"> */}
                  <div className="appColumn centered">
                    <div className="appColumn">
                      <div className="appTextColor12flex row space-between">
                        <span className="textColor14">{userD.userName}</span>
                        <div className="smallcard20" style={{ marginLeft: 8 }}>
                          <span className="textSmallItalic">{basketNos ? 'T ' + (basketNos + 1) : 'T 1'}</span>
                        </div>
                      </div>
                      <span className="appTextColor12flex textColor12">{userD.name}</span>
                    </div>
                    <div className="center flex-1 height-25">
                      <span className={`appTextColor12flex textSmall ${totalPointss > 0 ? 'green' : 'red'}`}>
                        Total points: {totalPointss ? totalPointss : '0'}
                      </span>
                      <span className={`appTextColor12flex textBigItalicCenter ${totalPerChanges > 0 ? 'green' : 'red'}`}>
                        Total % change: {totalPerChanges ? totalPerChanges.toFixed(2) + ' %' : '0 %'}
                      </span>
                    </div>
                  </div>
                {/* </div> */}
              </div>

              <div className="leaderboard-item containerVerticalSmall" style={{ marginTop: 20,marginBottom:20 }}>
                <div className="appTextColor12flex leftTextContainer">
                  <span className="textSecondary10">Crypto</span>
                </div>
                <div className="appTextColor12flex leftTextContainer">
                  <span className="textSecondary10">Price</span>
                </div>
                <div className="appTextColor12flex centered flex-1 height-25">
                  <span className="textSecondary10">Points</span>
                </div>
                <span className="appTextColor12flex textSecondary12">x</span>
                <div className="appTextColor12flex centered flex-1 height-25">
                  <span className="textSecondary10">Total Points</span>
                </div>
                <div className="appTextColor12flex centered flex-1 height-25">
                  <span className="textSecondary10">% Change</span>
                </div>
              </div>

              {baskets.stockList.map((item, index) => (
                <div className="leaderboard-item" key={index}>
                  <div className=" appTextColor12flex leftTextContainer">
                    <span className="textSmallPrimary">#{item.name}</span>
                  </div>
                  <div className=" appTextColor12flex leftTextContainer">
                    <span className="textSmallPrimary">Rs {item.price}</span>
                  </div>
                  {/* {item.assignpoint !== undefined && item.assignpoint !== null && ( */}
                    <div className="appTextColor12flex centered flex-1 height-25">
                      <span className={`textColor12 ${item.assignpoint > 0 ? 'green' : 'red'}`}>
                        {item.assignpoint !== undefined && item.assignpoint !== null ? item.assignpoint : '0'}
                        {/* {item.assignpoint ? item.assignpoint : '0'} */}
                        
                      </span>
                    </div>
                  {/* ) */}
                  {/* } */}

                  <div className="appTextColor12flex smallcard20">
                    <span className="text">{renderX(item.name, item.symbol, item.percentChange)}</span>
                  </div>

                  {/* {item.points !== undefined && item.points !== null && ( */}
                    <div className="appTextColor12flex centered flex-1 height-25">
                      <span className={`textColor12 ${item.points > 0 ? 'green' : 'red'}`}>
                        {item.points !== undefined && item.points ?  item.points: '0'}
                        </span>
                    </div>
                  {/* )} */}
                  {/* {item.percentChange && item.points !== null && ( */}
                    <div className="appTextColor12flex centered flex-1 height-25">
                      <span className={`textBigItalicCenter ${item.percentChange > 0 ? 'green' : 'red'}`}>
                        {/* {item.percentChange ? Number(item.percentChange).toFixed(2) + ' %' : '0 %'} */}
                        {item.percentChange && item.points !== null ? Number(item.percentChange).toFixed(2) + ' %' : '0 %'}
                      </span>
                    </div>
                  {/* )} */}
                </div>
              ))}

              {userid && userid === user.uid && baskType && baskType == 2 && (
                <div className="centered" style={{ marginTop: 20 }}>
                  <div className="longbutton">
                    <button onClick={() => gotoStockScreen(basket_id, item)}>Edit basket</button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

      </div>
      {loading && <LoadingScreen />}

    </div>
  );
};
export default BasketDetailScreen;

