// src/MarketListScreen.js
import React, { useState, useEffect } from "react";
// const cors = require('cors')({ origin: true });
import '../view/MarketListScreen.css';
import LoadingScreen from "./globals/LoadingScreen";
import { useLocation,useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { getDatabase, ref, get,limitToFirst,query as databaseQuery } from "firebase/database";

const MarketListScreen = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cryptoData, setCryptoData] = useState([]);
  const [sortType, setSortType] = useState(0);

  const db = getFirestore();


const fetchCryptoData = async () => {
  setLoading(true);
  
  try {
    const db = getDatabase();
    // Reference to the 'CryptoDataCurrent' path in Realtime Database
    const cryptoDataRef = ref(db, 'CryptoDataCurrent');
    const cryptoDataQuery = databaseQuery(cryptoDataRef, limitToFirst(100));
    const snapshot = await get(cryptoDataQuery);

    // Fetch the data from Realtime Database
    // const snapshot = await get(cryptoDataRef);

    if (snapshot.exists()) {
      // Convert the snapshot data to an array of objects
      const data = Object.values(snapshot.val());

      // Filter and transform the data
      const arr = ['usdt', 'usdc', 'dai'];
      const extractedData = data
        // You can uncomment the filter line if you want to exclude specific items
        // .filter(item => !arr.includes(item.symbol))
        .map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.current_price,
          image: item.image,
          percentChange: Number(item.percentChange1hr).toFixed(3),
          rank: item.market_cap_rank,
          percentChange24h: Number(item.percentChange24h).toFixed(3),
        }));

      // Sort the data based on the sortType
      if (sortType === 0) {
        extractedData.sort((a, b) => a.rank - b.rank);
      } else if (sortType === 1) {
        extractedData.sort((a, b) => b.percentChange - a.percentChange);
      } else if (sortType === 2) {
        extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
      }
      console.log('data_available'+extractedData);

      // Set the state with the extracted and sorted data
      setCryptoData(extractedData);
    } else {
      console.log('No crypto data available');
      setCryptoData([]);
    }
  } catch (error) {
    console.error('Error fetching crypto data from Realtime Database:', error.message);
  } finally {
    setLoading(false);
  }
};

const fetchCryptoData2 = async () => {
  setLoading(true);
  try {
    // Reference to the Firestore collection
    const cryptoDataCollectionRef = collection(db, 'CryptoDataCurrent');

    const cryptoDataQuery = query(cryptoDataCollectionRef, orderBy('market_cap_rank', 'asc'));

    // Get documents based on the query
    const querySnapshot = await getDocs(cryptoDataQuery);
    // Get all documents from the Firestore collection
    // const querySnapshot = await getDocs(cryptoDataCollectionRef);
    
    // Convert Firestore snapshot to an array of objects
    const data = querySnapshot.docs.map(doc => doc.data());
   
    // const extractedData = data.map(item => ({
    // Filter and transform the data
    const arr = ['usdt', 'usdc', 'dai'];
    const extractedData = data
      // .filter(item => !arr.includes(item.symbol))
      .map(item => ({
        id: item.id,
        symbol: item.symbol,
        name: item.name,
        price: item.current_price,
        image: item.image,
        percentChange: Number(item.percentChange1hr).toFixed(3),
        rank: item.market_cap_rank,
        percentChange24h: Number(item.percentChange24h).toFixed(3),
      }));

    // Sort the data based on the sortType
    if (sortType === 0) {
      extractedData.sort((a, b) => a.rank - b.rank);
    } else if (sortType === 1) {
      extractedData.sort((a, b) => b.percentChange - a.percentChange);
    } else if (sortType === 2) {
      extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
    }

    // Set the state with the extracted and sorted data
    setCryptoData(extractedData);
    setLoading(false);
    // setRefreshing(false);
  } catch (error) {
    console.error('Error fetching crypto data from Firestore:', error.message);
    setLoading(false);
    // setRefreshing(false);
  }
};
  const fetchCryptoData1 = async () => {
    setLoading(true);

    try {
    // const response = await fetch(
    //     'https://us-central1-tradingclash-a6d81.cloudfunctions.net/getAllCryptoMarketData',
    //     );
      const response = await fetch(
        // 'https://us-central1-tradingclash-a6d81.cloudfunctions.net/getAllCryptoMarketData',
        'https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=inr&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=1h&x_cg_pro_api_key=CG-1DrGqL1ynh59Hcpoo1fEsfzG',
        {
        //   mode: 'no-cors',
          headers: {
            'User-Agent': 'tradingclash/1.0',
            'Access-Control-Allow-Origin':'*',
            accept: 'application/json',
            // 'x-cg-pro-api-key': 'CG-1DrGqL1ynh59Hcpoo1fEsfzG'
            // 'Access-Control-Allow-Origin':'*',
            // 'Access-Control-Allow-Methods':'GET,POST,PATCH,OPTIONS'
          },
        }
      );

      console.log("error_sss"+response);
      if (!response.ok) {
        throw new Error('');
      }

      // const extractedData = await response.json();
      const data = await response.json();
      const extractedData = data.map(item => ({
        id: item.id,
        symbol: item.symbol,
        name: item.name,
        price: item.current_price,
        image: item.image,
        rank: item.market_cap_rank,
        percentChange24h: item.price_change_percentage_24h.toFixed(3),
        percentChange: item.price_change_percentage_1h_in_currency.toFixed(3),
      }));

      if (sortType === 0) {
        extractedData.sort((a, b) => a.rank - b.rank);
      } else if (sortType === 1) {
        extractedData.sort((a, b) => b.percentChange - a.percentChange);
      } else if (sortType === 2) {
        extractedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
      }

      setCryptoData(extractedData);
      setLoading(false);
    } catch (error) {
        console.error('Error fetching crypto data:', error.message);
    //   console.error('Error fetching crypto data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptoData();
  }, []);

  const sortByCryptoFun11 = (type) => {
    setSortType(type);
    // console.log('test_sort'+JSON.stringify(cryptoData));
    // console.log('test_sort'+type==1);
    console.log('test_sort', type == 1);

    let sortedData = [...cryptoData]; // Create a copy to avoid mutating state

    if (type === 0) {
      sortedData.sort((a, b) => a.rank - b.rank);
    } else if (type === 1) {
      sortedData.sort((a, b) => b.percentChange - a.percentChange);
    } else if (type === 2) {
      sortedData.sort((a, b) => b.percentChange24h - a.percentChange24h);
    }

    setCryptoData(sortedData);
  };
  
  const sortByCryptoFun = (type) => {
    console.log('test_sort'+JSON.stringify(cryptoData));

    setSortType(type);

    let extractedData = [...cryptoData]; // Create a copy to avoid mutating state

    if (type === 0) {
      cryptoData.sort((a, b) => a.rank - b.rank);
    } else if (type === 1) {
      cryptoData.sort((a, b) => b.percentChange - a.percentChange);
    } else if (type === 2) {
      cryptoData.sort((a, b) => {
        return b.percentChange24h-a.percentChange24h;
      });
      // cryptoData.sort((a, b) => b.percentChange24h - a.percentChange24h);
    }
    

    setCryptoData(cryptoData);
    // setCryptoData(cryptoData);

  };

  const onRefresh = () => {
    fetchCryptoData();
  };

  const gotoStockScreen = async (e_id, item) => {
    navigate('/cyptoselectstock', { state: { e_id, item } });
  };
  const gotoLeaderboardScreen = async () => {
    navigate('/leaderboardstock' );
  };

  return (
  // <div className="wrapper">

  <div className="container">

    {/* <div className="market-list-screen "> */}
    {/* {loading ? (
      // <p>Loading...</p>
      <LoadingScreen/>
    ) : ( */}
      <div className="crypto-list ">
        {/* <div 
          onClick={() => gotoStockScreen('11', null)}
          // onClick={gotoStockScreen}
          >
                <p className="appTextColor12 center">Create your tracking basket</p>
        </div> */}
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
      <p className="appTextColor14-0 app-margin-10">sort by: </p>

      <div
        className="submitButtonTopBar "
        onClick={() => sortByCryptoFun(0)}
        // style={sortType === 0 ? styles.buttonSelect : styles.button}
      >
        Rank
      </div>

      <div
        className="submitButtonTopBar app-margin-10"
        onClick={() => sortByCryptoFun(1)}
        // style={sortType === 1 x? { ...styles.buttonSelect, margin: '0 10px' } : {  margin: '0 10px' }}
      >
        % Change 1hr
      </div>

      <div
        className="submitButtonTopBar"
        onClick={() => sortByCryptoFun(2)}
        // style={sortType === 2 ? styles.buttonSelect : styles.button}
      >
        % Change 24hr
      </div>
    </div>

        <div  className="crypto-item">

            <div className="crypto-info ">
             <div style={{flex:1, }}> 
            <p className="appTextColor12Start ">Market cap</p>
            </div>
            <div style={{flex:1,
            // flexDirection:"row",
            display:'flex',
            // justifyContent:'center',alignContent:'center'
            }}>
            <div className="crypto-item-col">
              <p className="appTextColor12 ">Name</p>
              <p className="appTextColor12 ">Symbol</p>
            </div>
            </div>
            <div  style={{flex:1}}>
              <p className="appTextColor12End-1">Price</p>
            </div>

              <div  
              style={{flex:1}} 
              className="crypto-item-col">
              <p className="appTextColor12End-1">1hr % Change</p>
              <p className="appTextColor12End-1"> 24hr % Change</p>
              </div>
            </div>
          </div>
        {cryptoData.map((item,index) => (
          <div key={index} className="crypto-item">
            <div className="crypto-info ">
             <div style={{flex:1, }}> 
            <p className="appTextColor12Start ">{item.rank}</p>
            </div>
            <div style={{flex:1,flexDirection:"row",display:'flex',
            // justifyContent:'center',alignContent:'center'
            }}>
            <div style={{alignContent:"center",alignItems:'center'}}> 
            <img 
            src={item.image} 
            alt={item.symbol} className="crypto-image" />
            </div>
            <div className="crypto-item-col">
              <p className="appTextColor12Start  ">{item.name}</p>
              <p className="appTextColor12Start  ">{item.symbol}</p>
            </div>
            </div>
            <div  style={{flex:1}}>
              <p className="appTextColor12End-1">₹{item.price}</p>
            </div>

              <div 
               style={{flex:1}}
               className="crypto-item-col">
              <p className="appTextColor12End-1" style={{color:item.percentChange>0?'var(--app-green)':'var(--app-red)'}}>{item.percentChange}%</p>
              <p className="appTextColor12End-1" style={{color:item.percentChange>0?'var(--app-green)':'var(--app-red)'}}> {item.percentChange24h}%</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    {/* )} */}
          {/* <button onClick={onRefresh}>Refresh</button> */}
    {/* </div> */}
    {loading && <LoadingScreen/>}

   </div>
  //  </div>

  );
};

export default MarketListScreen;
