import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import '../../view/TopBar.css'
import logo from '../../assets/applogo.png'; // Assuming the logo image is in this path

// import portfolio from '../../assets/portfolio.png'; // Assuming the logo image is in this path
import userprofile from '../../assets/userprofile.png';
// import contests from '../../assets/contests.png';
// import marketlist from '../../assets/marketlist.svg';
import marketdata from '../../assets/marketdata1.png';
import marketdata1 from '../../assets/marketdata.svg';
import { ReactComponent as Logo } from '../../assets/marketdata.svg';
import { ReactComponent as Marketlist} from '../../assets/marketlist.svg';
import { ReactComponent as Portfolio} from '../../assets/portfolio.svg';
import { ReactComponent as Contests} from '../../assets/contests.svg';
import { ReactComponent as Globalchat} from '../../assets/globalchat.svg';

// import globalchat from '../../assets/globalchat.png';


const BottomBar = () => {
  return (
    <div className="botbar">
      <nav>
        <ul>
          {/* <li>
          <NavLink to="/login" activeClassName="active">Login</NavLink>
          </li> */}
          <li >
          <NavLink to="/market" 
          activeClassName="active" 
          // className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          className="nav-link"
          >
          {/* <img src={marketlist} 
          className="nav-icon" /> */}
          <div>
          <Marketlist style={{   width: '20px', height: '20px' }} />
          </div>

          <span className="bot-text-12">Markets</span>
          </NavLink>
            {/* <Link to="/market">Market List</Link> */}
          </li>
          <li>
          <NavLink to="/leaderboardstock" activeClassName="active">
          {/* <NavLink to="/cyptoselectstock" activeClassName="active"> */}
          {/* <img src={portfolio}
          className="nav-icon" /> */}
          <div>
          <Portfolio style={{   width: '20px', height: '20px' }} />
          </div>
          <span className="bot-text-12">Portfolio</span>  
          </NavLink>
          </li>
          <li>
          
          <NavLink to="/" exact="true"  activeClassName="active" className="default">
          {/* <NavLink to="/" exact  activeClassName="active" className="default"> */}
          {/* <svg className="nav-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 2L2 22h20L12 2z" fill="currentColor" />
         </svg> */}
        
      {/* <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
  <path fill="#FFFFFF" d="m22,4c0-.552.448-1,1-1s1,.448,1,1-.448,1-1,1-1-.448-1-1ZM3.762,11.41c.737-.547,1.215-1.423,1.215-2.41,0-1.654-1.346-3-3-3H.5c-.276,0-.5.224-.5.5s.224.5.5.5h1.477c1.103,0,2,.897,2,2s-.897,2-2,2H.5c-.276,0-.5.224-.5.5s.224.5.5.5h1.5c1.654,0,3,1.346,3,3s-1.346,3-3,3H.5c-.276,0-.5.224-.5.5s.224.5.5.5h1.5c2.206,0,4-1.794,4-4,0-1.574-.914-2.938-2.238-3.59Zm20.238-1.41v5c0,2.206-1.794,4-4,4s-4-1.794-4-4v-5c0-2.206,1.794-4,4-4s4,1.794,4,4Zm-1,0c0-1.654-1.346-3-3-3s-3,1.346-3,3v5c0,1.654,1.346,3,3,3s3-1.346,3-3v-5Zm-8.019,5c0,2.206-1.794,4-4,4s-4-1.794-4-4v-3.1c0-3.971,2.126-5.9,6.5-5.9.276,0,.5.224.5.5s-.224.5-.5.5c-3.804,0-5.5,1.512-5.5,4.9v.457c.734-.832,1.806-1.357,3-1.357,2.206,0,4,1.794,4,4Zm-1,0c0-1.654-1.346-3-3-3s-3,1.346-3,3,1.346,3,3,3,3-1.346,3-3Z"/>
</svg> */}
    {/* <Logo fill='red' stroke='green'/> */}
    <div>
         <Logo style={{   width: '20px', height: '20px' }} />
 
    </div>

          {/* <img src={marketdata} 
          className="nav-icon" /> */}
           <span className="bot-text-12">Dashboard</span>
           </NavLink>
            {/* <Link to="/">Market Data</Link> */}
          </li>
          <li>
          <NavLink to="/contests" activeClassName="active">
          {/* <img src={contests} 
          className="nav-icon" /> */}
          <div>
          <Contests style={{   width: '20px', height: '20px' }} />
          </div>
           <span className="bot-text-12">Contests</span>
           </NavLink>
            {/* <Link to="/contests">Contests</Link> */}
          </li>
          {/* <li>
          <NavLink to="/profile" activeClassName="active">
          <img src={userprofile}
          className="nav-icon" />
           <span> Profile</span>
           </NavLink>
          </li> */}
          <li>
          <NavLink to="/chatglobal" activeClassName="active">
          {/* <img src={globalchat}
          className="nav-icon" /> */}
          <div>
          <Globalchat style={{   width: '20px', height: '20px' }} />
          </div>
           <span className="bot-text-12">Feed</span>
           </NavLink>
            {/* <Link to="/chatglobal">Global Chat</Link> */}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default BottomBar;
