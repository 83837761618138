// Import necessary modules and libraries
import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { auth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
// import './BasketListScreen.css'; // Import custom CSS styles

// Import custom components
// import MySlider from "./globals/MySlider";
// import BackTopText from "./globals/BackTopText";
import LoadingScreen from "./globals/LoadingScreen";

const BasketListScreenStock = () => {
  const [rating, setRating] = useState(3); // Initial rating value
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState(null);
  const [basketid, setBasketid] = useState(null);
  const [stockTwoX, setStockTwoX] = useState(null);
  const [stockOneHalfX, setStockOneHalfX] = useState(null);
  const [stockThreeX, setStockThreeX] = useState(null);
  const [stockFourX, setStockFourX] = useState(null);
  const [stockOneX, setStockOneX] = useState(null);
  
  const [selectedStocks, setSelectedStocks] = useState(null);

  const [remTotalAmount, setRemTotalAmount] = useState(0);
  const [assignedAmount, setAssignedAmount] = useState(0);


  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const db = getFirestore();
  const user = auth.currentUser;
  const selectedStocks1 = location.state.selectedItems;
  const e_id = location.state.e_id;
  const item = location.state.item;
  const totalAmount = location.state.totalAmount;
  
  const baskType = location.state.baskType;
  const basket_id = location.state.basket_id;

  useEffect(() => {
    setSelectedStocks(selectedStocks1);
    const totalAssigned = selectedStocks1.reduce((acc, item) => acc + Number(item.assignedAmt), 0);
    setRemTotalAmount(totalAmount - totalAssigned);
  }, [selectedStocks, totalAmount]);

  // useEffect(() => {
  //   // console.log('Card items 111', totalAmount);
  //   setSelectedStocks(selectedStocks1);
  //   setRemTotalAmount(totalAmount);
    
  // }, [basketid]);

  const data = [
    { stockName: 'Stock 1', industryName: 'Industry 1', price: 'Price 1', percentIncrement: '1%' },
    { stockName: 'Stock 2', industryName: 'Industry 2', price: 'Price 2', percentIncrement: '2%' },
    { stockName: 'Stock 3', industryName: 'Industry 3', price: '20', percentIncrement: '2.1%' },
    { stockName: 'Stock 4', industryName: 'Industry 4', price: '300', percentIncrement: '10%' },
    { stockName: 'Stock 5', industryName: 'Industry 5', price: '500', percentIncrement: '30%' },
    { stockName: 'Stock 6', industryName: 'Industry 6', price: '60', percentIncrement: '-3%' }
  ];

  const checkIfBaskerUSerExist = async () => {
    try {
      const q = query(collection(db, 'ActiveNow', '' + e_id, 'Basket'), where('userid', '==', '' + user.uid));
      const querySnapshot = await getDocs(q);
      const numOfDocs = querySnapshot.size;
      console.log(numOfDocs + ' 1');
      return numOfDocs;
    } catch (error) {
      console.error('Error getting documents: ', error);
      return 0;
    }
  };

  const checkIfBasketExists = async () => {
    console.log('QueryLeague: ' + JSON.stringify(selectedStocks));
    gotoConfirmationScreen();
    // try {
    //   setLoading(true);
    //   console.log('Query: ' + stockOneX + stockTwoX + stockThreeX + stockFourX + ' ' + user.uid + "  bid: " + basket_id);
    //   const basketsRef = collection(db, 'ActiveNow', '' + e_id, 'Basket');
    //   const query1 = query(
    //     basketsRef,
    //     where('oneX', '==', stockOneX),
    //     where('twoX', '==', stockTwoX),
    //     where('threeX', '==', stockThreeX),
    //     where('fourX', '==', stockFourX),
    //     where('userid', '==', '' + user.uid)
    //   );

    //   const querySnapshot = await getDocs(query1);
    //   if (!querySnapshot.empty) {
    //     alert('Sorry!! You have already selected this basket');
    //     setLoading(false);
    //   } else {
    //     console.log('No documents found for the query.');
    //     const numBasket = await checkIfBaskerUSerExist();
    //     console.log(numBasket + ' 2');

    //     setLoading(false);

    //     if (numBasket < 11)
    //       gotoConfirmationScreen(numBasket);
    //     else
    //       alert('Sorry!! Maximum 10 baskets allowed for a contest');
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   console.error('Error getting documents: ', error);
    // }
  };

  const gotoConfirmationScreen = (numBasket) => {
    if (remTotalAmount==0) {
      if (baskType && baskType === 2) {
        // navigate('/ConfirmEditEntry', { state: { selectedStocks, item, e_id, stockOneHalfX, stockTwoX, stockThreeX, stockFourX, stockOneX, numBasket, basket_id } });
      } else {
        navigate('/confirmenrtystock', { state: { selectedStocks, item, e_id, numBasket } });
      }
    } else {
      showToast();
    }
  };

  const showToast = () => {
    alert('Please assign total amount to cryptos to proceed');
  };

  
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: 'red',
  });

  const setassignedAmount = (newValue, index, item) => {
    const newAmount = Number(newValue);

    // Calculate the potential new remaining total amount
    const newRemTotalAmount = remTotalAmount + item.assignedAmt - newAmount;

    // Validate that the new value does not exceed the remaining total amount
    if (newAmount <= remTotalAmount + item.assignedAmt) {
      const updatedStocks = [...selectedStocks];
      updatedStocks[index].assignedAmt = newAmount;
      setSelectedStocks(updatedStocks);
    } else {
      alert(`You cannot assign more than Rs ${remTotalAmount}`);
    }
  };
  
  return (
    <div className="container">
      <div className="scroll-view">
        {/* <BackTopText title='Create basket' /> */}
        <div className="summary">
          <p className="appTextColor12">Total amount must be {totalAmount}</p>
          <p className="appTextColor12">Remaining total amount {remTotalAmount}</p>
        </div>
        {selectedStocks && (
          <div>
            {selectedStocks.map((item, index) => (
              <div className="card" key={index}>
                <div className="rowView100per">
                  <div className="appColumn">
                    <p className="appTextColor12Start">{item.name}</p>
                    <p className="appTextColor12Start">Rs {item.price}</p>
                  </div>
                  <div className="app-rowView">
                    <p className="appTextColor12End" style={{ color: item.percentChange > 0 ? 'green' : 'red' }}>{item.percentChange} % 1hr</p>
                  </div>
                  <div className=''>
        {/* <p className="textSecondaryBold10 app-margin-20">Enter the total amount </p> */}
                        <input
                          type="numeric"
                          // placeholder="Enter name"
                          className="inputTextEdit-profile "
                          value={item.assignedAmt}
                          onChange={(e) => setassignedAmount(e.target.value,index,item)}
                          required
                          // min="0"
                          max={remTotalAmount + item.assignedAmt}
                          onFocus={(e) => {
                            if (e.target.value === '0') {
                              e.target.value = '';
                            }
                          }}
                        />
         </div>
                </div>
              </div>
            ))}
          </div>
        )}
       
        <div className="center" style={{marginTop:20}}>
        <div className="inputWithPrefix2  appPadding10"
          onClick={() => {
            checkIfBasketExists();
          }}
          disabled={!(stockTwoX && stockThreeX && stockFourX)}
          style={{color:"black",
           backgroundColor: stockTwoX && stockThreeX && stockFourX ? 'green' : 'grey' }}
        >Save basket</div>
        </div>
        {loading && <LoadingScreen />}

        {/* {loading && (
          <div className="loading">
            <div className="spinner"></div>
            <p>{loadingTxt}</p>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BasketListScreenStock;
