import React, { useState, useEffect } from "react";
// import { getFirestore, collection, doc, getDocs,getDoc, updateDoc, setDoc, arrayUnion, FieldValue, addDoc } from '@firebase/firestore';
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
// import { auth, db, database, FieldValue, doc,getDoc, getDocs, setDoc, updateDoc, addDoc, collection, ref, set, get } from "../firebaseconfig";
import { getFirestore, doc, getDocs,getDoc, setDoc, updateDoc, addDoc, collection,FieldValue, arrayUnion, increment } from "firebase/firestore";
// import { getDatabase, ref, set, get } from "firebase/database";
import { getDatabase, ref, set, push } from "firebase/database";

import LoadingScreen from "./globals/LoadingScreen";

const ConfirmEntryStock = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  const db = getFirestore();
  // const database = getDatabase();
  const rtdb = getDatabase();

//   const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const user = auth.currentUser;
  // const [loading, setLoading] = useState(false);

//  const { e_id, item,selectedStocks,
//      baskType, basket_id,
//      stockTwoX,stockThreeX,stockFourX ,
//      stockOneX,numBasket} = location.state;

    // const [loading, setLoading] = useState(false);
     const [loadingTxt, setLoadingTxt] = useState(null);
     const [basketid, setBasketid] = useState(null);
    //  const [balance, setBalance] = useState(null);
   
    //  const navigate = useNavigate();
    //  const location = useLocation();
    //  const user = auth.currentUser;
   
     const { selectedStocks, numBasket } = location.state;
   
     let basketNo = 0;
     if (numBasket) basketNo = numBasket;     

  useEffect(() => {
    // const user = auth.currentUser;
    console.log('user_uid'+user.uid);
    checkUserBalance();
  }, []);

  const checkUserBalance = async () => {
    setLoading(true);
    const walletRef = doc(db, 'UserWallet', '' + user.uid);
    const docSnap = await getDoc(walletRef);
    if (docSnap.exists()) {
      const amount1 = docSnap.data().amount;
      const amount = Number(amount1).toFixed(2);
      setBalance(amount);
      setLoading(false);
    } else {
      console.log('User wallet document does not exist');
      setLoading(false);
    }
  };

  const saveBasketData1 = async (createdAt) => {
    setLoadingTxt('Saving basket...');
    const entryId = '1';

    // const title = item.title;
    const title = 'Mock';

    const stockList = selectedStocks;
    const userid = user.uid;
    const points = 0;
    const percentChange = '0';

    try {
      const usersRef = collection(db, 'BasketMocks');
      const docRef = await addDoc(usersRef, {
        userid,
        stockList,
        title,
        createdAt,
        points,
        percentChange,
        entryId,
        basketNo
      });
      const docId = docRef.id;
      setBasketid(docId);
      return docId;
    } catch (error) {
      console.error('Error adding basket to Firestore:', error);
    }
  };


  const updateActiveData1 = async (basketId, createdAt) => {
    setLoadingTxt('Saving active details...');
    const uname = localStorage.getItem('userName');
    const uid = user.uid;
    // const e_id_s = "" + e_id.toString();
    const e_id_s = "1";

    const rank = 0;
    

    try {
      const userDocRef = doc(db, 'ActiveMocks', e_id_s, 'Basket', basketId);
      await setDoc(userDocRef, {
        // title: 'Mock',
        points: 0,
        rank: 0,
        stockList: selectedStocks,
        userid: uid,
        userName: uname,
        basketNo,
        createdAt
      });
      console.log('active Entry added to Firestore successfully');
    } catch (error) {
      console.error('Error adding active data to Firestore:', error);
    }
  };

  // Function to save basket data to Realtime Database
const saveBasketData = async (createdAt) => {
  setLoadingTxt('Saving basket...');
  
  const entryId = '1';
  const title = 'Mock';
  const stockList = selectedStocks;
  const userid = user.uid;
  const points = 0;
  const percentChange = '0';
  const basketNo = 1; // assuming basketNo is defined

  try {
    // const db = getDatabase();
    // Push new data under 'BasketMocks' and get the key (equivalent to Firestore's docId)
    const basketRef = ref(rtdb, 'BasketMocks');
    const newBasketRef = push(basketRef); // This generates a new unique key
    const docId = newBasketRef.key;

    await set(newBasketRef, {
      userid,
      stockList,
      title,
      createdAt,
      points,
      percentChange,
      entryId,
      basketNo
    });

    setBasketid(docId); // Save the basket ID in state
    return docId;
  } catch (error) {
    console.error('Error adding basket to Realtime Database:', error);
  }
};

// Function to update active data in Realtime Database
const updateActiveData = async (basketId, createdAt) => {
  setLoadingTxt('Saving active details...');
  
  const uname = localStorage.getItem('userName');
  const uid = user.uid;
  const e_id_s = "1"; // Assuming this is the entry ID or event ID
  const rank = 0;

  try {
    // const db = getDatabase();
    // Reference to the specific path in Realtime Database
    const activeDataRef = ref(rtdb, `ActiveMocks/${e_id_s}/Basket/${basketId}`);

    await set(activeDataRef, {
      points: 0,
      rank: 0,
      stockList: selectedStocks,
      userid: uid,
      userName: uname,
      basketNo,
      createdAt
    });

    console.log('Active entry added to Realtime Database successfully');
  } catch (error) {
    console.error('Error adding active data to Realtime Database:', error);
  }
};

  

  const saveAndCreateBasket = async (amount) => {
    try {
      setLoading(true);
      setLoadingTxt('Please wait...');
      const uid = user.uid;

      // if (balance < amount) {
      //   alert('Sorry!! You don’t have enough balance to join.');
      // } else {
        const createdAt = Date.now();

        const basketId = await saveBasketData(createdAt);
        // await saveUserData(basketId);
        await updateActiveData(basketId, createdAt);
        setLoadingTxt('Saving your basket...');
        // await updateContestSpots();
        // await updateUserWall(amount, createdAt);
        // await updateUserTotalCont();
        // await saveActiveContest(basketId, createdAt);

        alert('Success, You have joined a contest. ');
        navigate('/leaderboardstock');
      // }
      setLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  const updateUserTotalCont = async () => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        totalContests: increment(1),
        // totalContests: FieldValue.increment(1),
      });
      setLoading(false);
    } catch (error) {
      console.log('User error' + '--' + error);
      setLoading(false);
    }
  };



  return (
    <div className="container">
      <div className="safeAreaView">
        <div className="scrollView">
          {/* <Loader loading={loading} text="Please wait..." /> */}
          {/* <BackTopText title='' /> */}
          <div className="container">
            <div className="appTextColor14">CONFIRMATION</div>
            <div className="cryptoItem app-margin-20">
              <div>
                <p className="appTextColor12">Entry</p>
                <p className="appTextColor12">To Pay</p>
              </div>
              <div>
                <p className="appTextColor12">Free</p>
                <p className="appTextColor12">Free</p>
              </div>
            </div>
            {selectedStocks && user  && (
              <div>
                <div>
                  <p className="appTextColor12">Your wallet balance is Rs {balance}</p>
                </div>

              <div className="center app-margin-20">  
              <div className=" inputWithPrefix2  appPadding10"
              onClick={() => saveAndCreateBasket(Number(0))}
              >
                <p className="appTextColor12">Join Contest</p>
              </div>
              </div>

              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </div>
  );
};

export default ConfirmEntryStock;