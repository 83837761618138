import React, { useState, useEffect } from 'react';
import '../../view/CounterTimer.css'; // Import the CSS file for styles

const CountdownTimer3 = ({ difference, onHide }) => {
  const [countdown, setCountdown] = useState(difference);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      onHide();
    }
  }, [countdown, onHide]);

  useEffect(() => {
    setCountdown(difference);
  }, [difference]);

  const formatTime = (time) => {
    if (time <= 0) {
      onHide();
      return 'Entries Closed';
    }

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);

    return `${hours} : ${minutes < 10 ? '0' : ''}${minutes}`;
  };

  return (
    <div className="countdown-timer">
      <div className="timer-display">
        <span className="time-text">{formatTime(countdown)}</span>
      </div>
      <div className="time-labels">
        <span className="label">hours</span>
        <span className="label">minutes</span>
      </div>
    </div>
  );
};

export default CountdownTimer3;
