import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, arrayUnion, increment, doc, getDoc, setDoc, updateDoc, collection } from "firebase/firestore";
// import Loader from "./globals/Loader";
// import BackTopText from "./globals/BackTopText";
// import './UserProfile.css';
import { useAuth } from './globals/AuthProvider';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import EditUserProfile from "./EditUserProfile";


const UserProfile = () => {
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [gender, setGender] = useState('');
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [totalContests, setTotalContests] = useState('');
  
  const navigate = useNavigate();
  
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  const { currentUser } = useAuth();

  const authUser = auth.currentUser;

  useEffect(() => {
    setLoading(true);
    // setLoadingText('Please wait...');
    // if (authUser) {
    //   fetchUserData();
    //   fetchUserDataFromDB();
    // } else {
    //   navigate('/login');
    // }
    // setLoading(false);

    const fetchData = async () => {
      if (authUser) {
        await fetchUserData();
        await fetchUserDataFromDB();
      } else {
        navigate('/login');
      }
      setLoading(false);
    };
  
    fetchData();

    return () => {
      // Clean up listeners or other resources here
    };
  }, [authUser]);

  const fetchUserDataFromDB = async () => {
    try {
      const userDocRef = doc(collection(db, 'users'), authUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const docData = userDocSnapshot.data();
        setTotalContests(docData.totalContests || 0);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserData = async () => {
    // localStorage.setItem('key', 'value')
    const userName = localStorage.getItem('userName');
    const name = localStorage.getItem('name');
    const gender = localStorage.getItem('email');
    const profileImg = localStorage.getItem('profileImg');
    
    if (userName) setUserName(userName);
    if (name) setName(name);
    if (gender) setGender(gender);
    if (profileImg) setProfileImg(profileImg);
  };

  const handleSaveUser = async () => {
    setLoading(true);
    setLoadingText('Updating profile...');
    if (userName) {
      try {
        if (!authUser) {
          throw new Error('User not authenticated.');
        }

        const userDocRef = doc(db, 'users', authUser.uid);
        await updateDoc(userDocRef, {
          name: name,
          userName: userName,
          email: gender,
        });

        localStorage.setItem('name', name);
        localStorage.setItem('userName', userName);
        localStorage.setItem('email', gender);

        alert('Profile Updated.');
        setLoading(false);
      } catch (error) {
        alert(error.message);
        console.log('User error:', error);
        setLoading(false);
      }
    } else {
      alert('Please enter a user name');
      setLoading(false);
    }
  };

  const signOut = async () => {
    setLoading(true);
    setLoadingText('Signing out...');
    try {
      await auth.signOut();
      localStorage.clear();
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      setLoading(false);
    }
  };

  const chooseImageFromGallery = async (event) => {
    setLoading(true);
    setLoadingText('Uploading image...');
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToFirebase(file);
      const userDocRef = doc(db, 'users', authUser.uid);
      await updateDoc(userDocRef, {
        userImg: imageUrl,
      });
      localStorage.setItem('profileImg', imageUrl);
      setProfileImg(imageUrl);
      setLoading(false);
    } else {
      console.log('No file selected');
      setLoading(false);
    }
  };

  const uploadImageToFirebase = async (file) => {
    try {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`images/profile/${authUser.uid}.jpg`);
      await imageRef.put(file);
      const downloadURL = await imageRef.getDownloadURL();
      console.log('Image uploaded successfully:', downloadURL);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
      throw error;
    }
  };

  const editProfile = () => {
    setLoginPopupOpen(true);
    // navigate('/editprofile');
  };
  const gotowalletscreen = () => {
    // setLoginPopupOpen(true);
    navigate('/walletscreen');
  };
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // Set to true to open on navigate

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };

  
  return (
    // app-container3
    <div className=" ">
      {isLoginPopupOpen && <EditUserProfile isOpen={isLoginPopupOpen} onClose={closeLoginPopup} isCancelable={true}/>}
      {/* <EditUserProfile /> */}
      {/* {isLoading && (
        <Loader loading={isLoading} text={loadingText} />
      )} */}
      <div className="safe-area2"
      //  onClick={closeLoginPopup}
       >
        {/* <BackTopText title='User Profile' /> */}
        <div className="profile-card2">
          {showUserProfile && (
            <div className="profile-details">
              <div className="profile-picture">
                <label htmlFor="file-input">
                  <img
                    src={profileImg || require('../assets/applogo.png')}
                    alt="Profile"
                    className="app-profile-image"
                  />
                </label>
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={chooseImageFromGallery}
                  style={{ display: 'none' }}
                />
              </div>
              <div className="profile-info">
                <div className="appTextColor14">{name}</div>
                <div className="appTextColor14">{userName}</div>
                <div className="center app-margin-20">
                <div className="inputWithPrefix2" 
                onClick={editProfile}>
                  Edit Profile
                </div>
                </div>

                {/* <div className="center app-margin-20">
                <div className="inputWithPrefix2" 
                // onClick={editProfile}
                onClick={() => gotowalletscreen()}
                >
                  Wallet
                </div>
                </div> */}

                <div className="appTextColor14">
                  <span >Total contests played: </span>
                  <span>{totalContests}</span>
                </div>
              </div>
            </div>
          )}
          
        </div>
        <div className=" center2">
            <div
            //  onClick={signOut}
             onClick={() => signOut()}

             className="inputWithPrefix2 appPadding10 ">
              {/* <img src="signout-icon.png" alt="Sign Out" /> */}
              Sign Out
            </div>
          </div>
      </div>
      
    </div>
  );
};

export default UserProfile;
