import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, arrayUnion, increment, doc, getDoc, setDoc, updateDoc, collection ,addDoc} from "firebase/firestore";
// import Loader from "./globals/Loader";
// import BackTopText from "./globals/BackTopText";
// import './UserProfile.css';
import { useAuth } from './globals/AuthProvider';
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const EditUserProfile = ({ isOpen, onClose, isCancelable }) => {
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [gender, setGender] = useState('');
  const [showUserProfile, setShowUserProfile] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [totalContests, setTotalContests] = useState('');
  const [isClosed, setClosed] = useState(true);

  const navigate = useNavigate();
  
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();


  
  const { currentUser } = useAuth();

  const authUser = auth.currentUser;

  useEffect(() => {
    setLoading(true);
    setLoadingText('Please wait...');
    if (authUser) {

      const isLogin = localStorage.getItem('isLoggedin');

      // if(isLogin=='1')
      fetchUserData();
      // fetchUserDataFromDB();
    } else {
      navigate('/login');
    }
    setLoading(false);
  }, [authUser, navigate]);


  const handleSaveUser = async () => {
    setLoading(true);
    setLoadingText('Updating profile...');

    if (userName) {
      try {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);

        await setDoc(userDocRef, {
          name,
          userName,
          email: gender,
        }, { merge: true });
  
        // Update local storage

        localStorage.setItem('name', name);
        localStorage.setItem('userName', userName);
        localStorage.setItem('email', gender);


        const isLogin = localStorage.getItem('isLoggedin');
        if(isLogin=='2'){
        if(!isCancelable)
        updateWalletAndLogTransaction();  //add amount for sign up
        
        navigate('/'); // Navigate after profile check
        localStorage.setItem('isLoggedin', '1');
        }else{
          alert('Profile Updated.');
        }
        // alert('Profile Updated.');
      } catch (error) {
        alert(error.message);
        console.error('Error updating profile:', error);
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please enter a user name');
      setLoading(false);
    }
  };

  async function updateWalletAndLogTransaction() {
    const db = getFirestore();
  
    try {
      // Reference to the user's wallet document in Firestore
      const wallDocRef = doc(db, 'UserWallet', authUser.uid);
  
      // Set the wallet amount to 5000
      await setDoc(wallDocRef, {
        amount: 5000,
      });
  
      // Get the current timestamp
      const createdAt = Date.now();
  
      // Reference to the transactions collection for the user
      const transactionsRef = collection(db, 'Transactions', authUser.uid, 'All');
  
      // Add a new transaction record
      await addDoc(transactionsRef, {
        amount: 5000,
        type: 0, // Type 0 might indicate a specific transaction type, like "credit"
        createdAt: createdAt,
        senderId: 'admin', // Assuming the transaction is being added by an admin
      });
  
      console.log('Wallet updated and transaction logged successfully.');
    } catch (error) {
      console.error('Error updating wallet and logging transaction:', error);
    }
  }

  const fetchUserDataFromDB = async () => {
    try {
      const userDocRef = doc(collection(db, 'users'), authUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const docData = userDocSnapshot.data();
        setTotalContests(docData.totalContests || 0);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchUserData = async () => {
    // localStorage.setItem('key', 'value')
    const userName = localStorage.getItem('userName');
    const name = localStorage.getItem('name');
    const gender = localStorage.getItem('email');
    const profileImg = localStorage.getItem('profileImg');
    
    if (userName) setUserName(userName);
    if (name) setName(name);
    if (gender) setGender(gender);
    if (profileImg) setProfileImg(profileImg);
  };

  const handleSaveUser11 = async () => {
    setLoading(true);
    setLoadingText('Updating profile...');
    if (userName) {
      try {
        if (!authUser) {
          throw new Error('User not authenticated.');
        }

        const userDocRef = doc(db, 'users', authUser.uid);
        await updateDoc(userDocRef, {
          name: name,
          userName: userName,
          email: gender,
        });

        localStorage.setItem('name', name);
        localStorage.setItem('userName', userName);
        localStorage.setItem('email', gender);

        alert('Profile Updated.');
        setLoading(false);
      } catch (error) {
        alert(error.message);
        console.log('User error:', error);
        setLoading(false);
      }
    } else {
      alert('Please enter a user name');
      setLoading(false);
    }
  };

  const signOut = async () => {
    setLoading(true);
    setLoadingText('Signing out...');
    try {
      await auth.signOut();
      localStorage.clear();
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      setLoading(false);
    }
  };

  const chooseImageFromGallery = async (event) => {
    setLoading(true);
    setLoadingText('Uploading image...');
    const file = event.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToFirebase(file);
      const userDocRef = doc(db, 'users', authUser.uid);
      await updateDoc(userDocRef, {
        userImg: imageUrl,
      });
      localStorage.setItem('profileImg', imageUrl);
      setProfileImg(imageUrl);
      setLoading(false);
    } else {
      console.log('No file selected');
      setLoading(false);
    }
  };

  const uploadImageToFirebase = async (file) => {
    try {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`images/profile/${authUser.uid}.jpg`);
      await imageRef.put(file);
      const downloadURL = await imageRef.getDownloadURL();
      console.log('Image uploaded successfully:', downloadURL);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image to Firebase Storage:', error);
      throw error;
    }
  };

  const editProfile = () => {
    navigate('/UserEditProfile');
  };

  const setonClose = (closed)=>{
    setClosed(false)
  };

  const isMobile = window.innerWidth <= 768; // Adjust as per your mobile screen size criteria


  return (

    // <Popup
    //   open={isOpen}
    //   onClose={onClose}
    //   modal
    //   closeOnDocumentClick
    //   className="custom-popup" // Add a custom class name
    // >
<Popup
      open={isOpen}
      onClose={onClose}
      modal
      closeOnDocumentClick={isCancelable}
      contentStyle={{
        width: isMobile ? '70%' : '25%', // Adjust width based on screen size
        // width: '40%',
        height: isMobile ? '90%' : '80%',
        alignContent:'center',
        // alignItems:'center',
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '25px',
        zIndex:'100',
        // overflow: 'hidden',
        overflowY:'hidden',
        padding: '0px', // Remove any default padding
        boxSizing: 'border-box' // Ensure padding and border are included in the element's total width and height

      }} // Add inline styles
      overlayStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Custom background for overlay
      }}
    >
    {/* </Popup> */}
    {/* // <Popup open={isOpen} onClose={onClose} modal closeOnDocumentClick> */}
    {/* <Popup open={isClosed} onClose={setonClose} modal> */}
    {/* // <Popup trigger={<button className="button"> Open Login Popup </button>} modal nested> */}
      {/* {(close) => ( */}
        <div className="popup">
          <div className="popup-inner">
          {isCancelable && 
            <button className="close" onClick={onClose}>&times;</button>
          }  
            <div className="profile-details">
              <p className="textColor10 textCenter bold italic" style={{ fontSize: 16 }}>
                {isCancelable ? 'Edit Profile': 'Sign up'}
              </p>
              {showUserProfile && (
                <div style={{alignContent:'center',}} >
                  <div className="profile-picture center">
                    <div htmlFor="file-input">
                      <img
                        src={profileImg || require('../assets/applogo.png')}
                        alt="Profile"
                        className="app-profile-image"
                      />
                    </div>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={chooseImageFromGallery}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <div className="column profile-info">
                    <div className="center app-margin-10">
                      <label className="">
                        {/* <p className="textSecondaryBold10">Name</p> */}
                        <input
                          type="text"
                          placeholder="Enter name"
                          className="inputTextEdit-profile"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </label>
                      <label>
                        {/* <p className="textSecondaryBold10">Username</p> */}
                        <input
                          type="text"
                          placeholder="Enter username"
                          className="inputTextEdit-profile"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          required
                        />
                      </label>
                      <label>
                        {/* <p className="textSecondaryBold10">Email</p> */}
                        <input
                          type="text"
                          placeholder="Enter email"
                          className="inputTextEdit-profile"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                          required
                        />
                      </label>
                      <div
                        onClick={handleSaveUser}
                        className="submitButton-edit-profile appPadding10"
                      >
                        Save
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      {/* )} */}
    </Popup>

    // app-container3
    // <div className="modal-overlay">
    //   <div className="modal">
    // <div className="popup">
    //  <div className="popup-inner">
    //     <div className="">

    //     <p className="textColor12 textCenter bold italic" style={{ fontSize: 20 }}>
    //       Edit Profile</p>
    //       {showUserProfile && (
    //         <div className="profile-details ">
    //           <div className="profile-picture">
    //             <label htmlFor="file-input">
    //               <img
    //                 src={profileImg || require('../assets/applogo.png')}
    //                 alt="Profile"
    //                 className="app-profile-image"
    //               />
    //             </label>
    //             <input
    //               id="file-input"
    //               type="file"
    //               accept="image/*"
    //               onChange={chooseImageFromGallery}
    //               style={{ display: 'none' }}
    //             />
    //           {/* <div className="center">  
    //           <div  className=" submitButton-edit-profile appPadding10 "
    //            onClick={chooseImageFromGallery}>
    //             Change Profile Picture
    //           </div>
    //           </div> */}

    //           </div>
    //           <div className="column profile-info">
    //             <div className="center app-margin-20">
    //             <label className="">
    //         <p className="textSecondaryBold12">Name</p>
    //         <input
    //       type="text"
    //       placeholder="Enter namme"
    //       className="inputTextEdit-profile"
    //       value={name}
    //       onChange={(e) => setName(e.target.value)}
    //       required
    //     />
    //         {/* <input
    //           type="text"
    //           value={name}
    //           onChange={(e) => setName(e.target.value)}
    //         /> */}
    //       </label>
    //       <label>
    //       <p className="textSecondaryBold12">User Name</p>
    //       <input
    //       type="text"
    //       placeholder="Enter userName"
    //       className="inputTextEdit-profile"
    //       value={userName}
    //       onChange={(e) => setUserName(e.target.value)}
    //       required
    //     />
    //         {/* <input
    //           type="text"
    //           value={userName}
    //           onChange={(e) => setUserName(e.target.value)}
    //         /> */}
    //       </label>
    //       <label>
    //       <p className="textSecondaryBold12">Email</p>
    //       <input
    //       type="text"
    //       placeholder="Enter email"
    //       className="inputTextEdit-profile"
    //       value={gender}
    //       onChange={(e) => setGender(e.target.value)}
    //       required
    //     />
    //         {/* <input
    //           type="text"
    //           value={gender}
    //           onChange={(e) => setGender(e.target.value)}
    //         /> */}
    //       </label>
    //       {/* <div className=" center"> */}
    //         <div
    //          onClick={handleSaveUser}
    //          className="submitButton-edit-profile appPadding10 ">
    //           {/* <img src="signout-icon.png" alt="Save" /> */}
    //           Save
    //         </div>
    //       {/* </div> */}
    //       {/* <button onClick={handleSaveUser}>Save</button>                 */}
    //             </div>


    //           </div>
    //         </div>
    //       )}
          
    //     </div>
    //   </div>
      
    // </div>
  );
};

export default EditUserProfile;
