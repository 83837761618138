import React, { useState, useEffect } from "react";
// import { getFirestore, collection, doc, getDocs,getDoc, updateDoc, setDoc, arrayUnion, FieldValue, addDoc } from '@firebase/firestore';
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
// import { auth, db, database, FieldValue, doc,getDoc, getDocs, setDoc, updateDoc, addDoc, collection, ref, set, get } from "../firebaseconfig";
import { getFirestore, doc, getDocs,getDoc, setDoc, updateDoc, addDoc, collection,FieldValue, arrayUnion, increment } from "firebase/firestore";
import { getDatabase, ref, set, get } from "firebase/database";
import LoadingScreen from "./globals/LoadingScreen";

const ConfirmEntry = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  const db = getFirestore();
  const database = getDatabase();
//   const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const user = auth.currentUser;
  // const [loading, setLoading] = useState(false);

//  const { e_id, item,selectedStocks,
//      baskType, basket_id,
//      stockTwoX,stockThreeX,stockFourX ,
//      stockOneX,numBasket} = location.state;

    // const [loading, setLoading] = useState(false);
     const [loadingTxt, setLoadingTxt] = useState(null);
     const [basketid, setBasketid] = useState(null);
    //  const [balance, setBalance] = useState(null);
   
    //  const navigate = useNavigate();
    //  const location = useLocation();
    //  const user = auth.currentUser;
   
     const { selectedStocks, e_id, item, stockOneHalfX, stockTwoX, stockThreeX, stockFourX, stockOneX, numBasket } = location.state;
   
     let basketNo = 0;
     if (numBasket) basketNo = numBasket;     

  useEffect(() => {
    // const user = auth.currentUser;
    console.log('user_uid'+user.uid);
    checkUserBalance();
  }, []);

  const checkUserBalance1 = async () => {
    setLoading(true);
    const user = auth.currentUser;
    const walletRef = doc(db, 'UserWallet', user.uid);
    try {
      const docSnap = await getDocs(walletRef);
      if (docSnap.exists()) {
        const amount = Number(docSnap.data().amount).toFixed(2);
        setBalance(amount);
      } else {
        console.log('User wallet document does not exist');
      }
    } catch (error) {
      console.error('Error fetching user balance:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveBasketData1 = async (createdAt) => {
    setLoading(true);
    const user = auth.currentUser;

    const amount = item.amount || 0;
    const entryId = e_id;
    const title = item.title;
    const stockList = selectedStocks;
    const userid = user.uid;
    const points = 0;
    const percentChange = '0';
    try {
      const usersRef = collection(db, 'Baskets');
      const docRef = await addDoc(usersRef, {
        userid,
        stockList,
        title,
        amount,
        createdAt,
        points,
        percentChange,
        entryId,
        twoX: stockTwoX,
        threeX: stockThreeX,
        fourX: stockFourX,
        oneX: stockOneX,
        basketNo: numBasket
      });
      const docId = docRef.id;
      console.log('Data added to Firestore successfully', docId);
      return docId;
    } catch (error) {
      console.error('Error adding basket to Firestore:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveAndCreateBasket1 = async (amount) => {
    try {
      setLoading(true);
      if (balance < amount) {
        alert('Sorry!!', 'You dont have enough balance to join.');
      } else {
        const createdAt = Date.now();
        const basketId = await saveBasketData(createdAt);
        console.log('Basket ID:', basketId);
        // Further logic to save user data and update Firestore
        setLoading(false);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  const checkUserBalance = async () => {
    setLoading(true);
    const walletRef = doc(db, 'UserWallet', '' + user.uid);
    const docSnap = await getDoc(walletRef);
    if (docSnap.exists()) {
      const amount1 = docSnap.data().amount;
      const amount = Number(amount1).toFixed(2);
      setBalance(amount);
      setLoading(false);
    } else {
      console.log('User wallet document does not exist');
      setLoading(false);
    }
  };

  const saveBasketData = async (createdAt) => {
    setLoadingTxt('Saving basket...');
    const amount = item.amount || 0;
    const entry = item.entry || 0;
    const entryId = e_id;
    const title = item.title;
    const stockList = selectedStocks;
    const userid = user.uid;
    const points = 0;
    const percentChange = '0';
    const twoX = stockTwoX;
    const threeX = stockThreeX;
    const fourX = stockFourX;
    const oneX = stockOneX;

    try {
      const usersRef = collection(db, 'Baskets');
      const docRef = await addDoc(usersRef, {
        userid,
        stockList,
        title,
        amount,
        createdAt,
        points,
        percentChange,
        entryId,
        twoX,
        threeX,
        fourX,
        oneX,
        basketNo
      });
      const docId = docRef.id;
      setBasketid(docId);
      return docId;
    } catch (error) {
      console.error('Error adding basket to Firestore:', error);
    }
  };

  const updateEntryData = async (b_id, time) => {
    setLoadingTxt('Saving entry details...');
    const uid = user.uid;
    try {
      const userDocRef = doc(db, 'TradingData', e_id, 'Entry', e_id);
      await setDoc(userDocRef, {
        title: item.title,
        basketList: FieldValue.arrayUnion(basketid),
        userList: FieldValue.arrayUnion(uid),
        amount: item.amount,
      });
      console.log('Entry added to Firestore successfully');
    } catch (error) {
      console.error('Error adding entry to Firestore:', error);
    }
  };

  const updateUserWall = async (amount, createdAt) => {
    const userid = user.uid;
    const walletRef = doc(db, 'UserWallet', userid);
    await updateDoc(walletRef, {
        amount: increment(-amount),
    //   amount: FieldValue.increment(-amount),
    });

    const transactionsRef = collection(db, 'Transactions', '' + userid, 'All');
    await addDoc(transactionsRef, {
      amount: amount,
      type: 2,
      createdAt: createdAt,
      senderId: 'admin',
    });
  };

  const updateContestSpots = async () => {
    const e_id_s = "" + e_id.toString();
    const userDocRef = doc(db, 'Contests', e_id_s);
    await updateDoc(userDocRef, {
        spotsTaken: increment(1),
        spotsTotal: increment(-1),
    //   spotsTaken: FieldValue.increment(1),
    //   spotsTotal: FieldValue.increment(-1),
    });
  };

  const updateActiveData = async (basketId, createdAt) => {
    setLoadingTxt('Saving active details...');
    const uname = localStorage.getItem('userName');
    const uid = user.uid;
    const e_id_s = "" + e_id.toString();
    const rank = 0;
    const twoX = stockTwoX;
    const threeX = stockThreeX;
    const fourX = stockFourX;
    const oneX = stockOneX;

    try {
      const userDocRef = doc(db, 'ActiveNow', e_id_s, 'Basket', basketId);
      await setDoc(userDocRef, {
        title: item.title,
        points: 0,
        rank: 0,
        stockList: selectedStocks,
        userid: uid,
        userName: uname,
        twoX,
        threeX,
        fourX,
        oneX,
        basketNo,
        createdAt
      });
      console.log('active Entry added to Firestore successfully');
    } catch (error) {
      console.error('Error adding active data to Firestore:', error);
    }
  };

  const saveUserData = async (basketId) => {
    setLoadingTxt('Updating your event...');
    const uid = user.uid;
    try {
      const userDocRef = doc(db, 'UsersEntries', uid);
      const entryCollectionRef = collection(userDocRef, 'Entry');
      const e_idDocumentRef = doc(entryCollectionRef, e_id);

      await setDoc(e_idDocumentRef, {
        basketList: arrayUnion(basketId),
        // basketList: FieldValue.arrayUnion(basketId),
      });
      console.log('Data added to Firestore successfully');
    } catch (error) {
      console.error('Error adding user to Firestore:', error);
    }
  };

  const checkIfContestIsActive = async () => {
    const db = getFirestore(); // Get Firestore instance
    const e_id_s = "" + e_id.toString();

    try {
      const docRef = doc(db, 'Contests', e_id_s); // Reference to the document
      const docSnapshot = await getDoc(docRef); // Get document snapshot
      
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        console.log('before_isactive ' + docData.isActive + ' --' + e_id_s);
        // Return true if isActive is equal to 0, otherwise return false
        return docData.isActive === 0;
      } else {
        // Document with the provided ID does not exist
        return false;
      }
    } catch (error) {
      console.error('An error occurred while checking if contest is active:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };


  const saveAndCreateBasket = async (amount) => {
    try {
      setLoading(true);
      setLoadingTxt('Please wait...');
      const uid = user.uid;

      const is_active= await checkIfContestIsActive();
      // console.log("check_is_active"+is_active);
      if(!is_active){
        alert('Sorry!! This contest has been started. You can join another contest.');
        navigate('/contests');
      }else{

      if (balance < amount) {
        alert('Sorry!! You don’t have enough balance to join.');
      } else {
        const createdAt = Date.now();

        const basketId = await saveBasketData(createdAt);
        await saveUserData(basketId);
        await updateActiveData(basketId, createdAt);
        setLoadingTxt('Saving your basket...');
        await updateContestSpots();
        await updateUserWall(amount, createdAt);
        await updateUserTotalCont();
        await saveActiveContest(basketId, createdAt);

        // alert('Success, You have joined a contest. ');
        navigate('/contests');
      }
      }
      setLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);
      setLoading(false);
    }
  };

  const updateUserTotalCont = async () => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        totalContests: increment(1),
        // totalContests: FieldValue.increment(1),
      });
      setLoading(false);
    } catch (error) {
      console.log('User error' + '--' + error);
      setLoading(false);
    }
  };

  const saveActiveContest = async (basketId, createdAt) => {
    const uid = user.uid;
    const e_id_s = "" + e_id.toString();

    const contestRef = ref(database, `UserContest/${uid}/${e_id_s}`);

    try {
      const snapshot = await get(contestRef);
      if (!snapshot.exists()) {
        await set(contestRef, {
          title: item.title,
          userid: uid,
          basketNo,
          entry: item.entry,
          createdAt,
        });
      }

      const basketRef = ref(database, `UserContest/${uid}/${e_id_s}/Baskets/${basketId}`);
      await set(basketRef, {
        createdAt,
        basketNo,
      });

      console.log('Data saved successfully.');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  return (
    <div className="container">
      <div className="safeAreaView">
        <div className="scrollView">
          {/* <Loader loading={loading} text="Please wait..." /> */}
          {/* <BackTopText title='' /> */}
          <div className="container center">
            <div className="inputWithPrefix-contest "> 
            {/* appPadding30 */}
            <div className="appTextColor14">CONFIRMATION</div>
            <div className="cryptoItem">
            {/* app-margin-20 */}
              <div className="app-margin-10">
                <p className="appTextColor12">Entry</p>
                <p className="appTextColor12">To Pay</p>
              </div>
              <div className="app-margin-10">
                <p className="appTextColor12">Rs {item.entry}</p>
                <p className="appTextColor12">Rs {item.entry}</p>
              </div>
            </div>
            </div>

            {selectedStocks && user && item && (
              <div>
                <div>
                  <p className="appTextColor12">Your wallet balance is Rs {balance}</p>
                </div>

              <div className="center app-margin-20">  
              <div className=" inputWithPrefix3  appPadding10"
              onClick={() => saveAndCreateBasket(Number(item.entry))}
              >
                <p className="appTextColor12">Join Contest</p>
              </div>
              </div>

              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </div>
  );
};

export default ConfirmEntry;