// Import necessary modules and libraries
import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { auth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
// import './BasketListScreen.css'; // Import custom CSS styles

// Import custom components
// import MySlider from "./globals/MySlider";
// import BackTopText from "./globals/BackTopText";
import LoadingScreen from "./globals/LoadingScreen";

const BasketListScreen = () => {
  const [rating, setRating] = useState(3); // Initial rating value
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingTxt, setLoadingTxt] = useState(null);
  const [basketid, setBasketid] = useState(null);
  const [stockTwoX, setStockTwoX] = useState(null);
  const [stockOneHalfX, setStockOneHalfX] = useState(null);
  const [stockThreeX, setStockThreeX] = useState(null);
  const [stockFourX, setStockFourX] = useState(null);
  const [stockOneX, setStockOneX] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const db = getFirestore();
  const user = auth.currentUser;
  const selectedStocks = location.state.selectedItems;
  const e_id = location.state.e_id;
  const item = location.state.item;
  const baskType = location.state.baskType;
  const basket_id = location.state.basket_id;

  useEffect(() => {
    console.log('Card items 111', selectedStocks);
  }, [basketid]);

  const data = [
    { stockName: 'Stock 1', industryName: 'Industry 1', price: 'Price 1', percentIncrement: '1%' },
    { stockName: 'Stock 2', industryName: 'Industry 2', price: 'Price 2', percentIncrement: '2%' },
    { stockName: 'Stock 3', industryName: 'Industry 3', price: '20', percentIncrement: '2.1%' },
    { stockName: 'Stock 4', industryName: 'Industry 4', price: '300', percentIncrement: '10%' },
    { stockName: 'Stock 5', industryName: 'Industry 5', price: '500', percentIncrement: '30%' },
    { stockName: 'Stock 6', industryName: 'Industry 6', price: '60', percentIncrement: '-3%' }
  ];

  const checkIfBaskerUSerExist = async () => {
    try {
      const q = query(collection(db, 'ActiveNow', '' + e_id, 'Basket'), where('userid', '==', '' + user.uid));
      const querySnapshot = await getDocs(q);
      const numOfDocs = querySnapshot.size;
      console.log(numOfDocs + ' 1');
      return numOfDocs;
    } catch (error) {
      console.error('Error getting documents: ', error);
      return 0;
    }
  };

  const checkIfBasketExists = async () => {
    try {
      setLoading(true);
      console.log('Query: ' + stockOneX + stockTwoX + stockThreeX + stockFourX + ' ' + user.uid + "  bid: " + basket_id);
      const basketsRef = collection(db, 'ActiveNow', '' + e_id, 'Basket');
      const query1 = query(
        basketsRef,
        where('oneX', '==', stockOneX),
        where('twoX', '==', stockTwoX),
        where('threeX', '==', stockThreeX),
        where('fourX', '==', stockFourX),
        where('userid', '==', '' + user.uid)
      );

      const querySnapshot = await getDocs(query1);
      if (!querySnapshot.empty) {
        alert('Sorry!! You have already selected this basket');
        setLoading(false);
      } else {
        console.log('No documents found for the query.');
        const numBasket = await checkIfBaskerUSerExist();
        console.log(numBasket + ' 2');

        setLoading(false);

        if (numBasket < 11)
          gotoConfirmationScreen(numBasket);
        else
          alert('Sorry!! Maximum 10 baskets allowed for a contest');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error getting documents: ', error);
    }
  };

  const gotoConfirmationScreen = (numBasket) => {
    if (stockThreeX && stockTwoX && stockFourX) {
      if (baskType && baskType === 2) {
        navigate('/ConfirmEditEntry', { state: { selectedStocks, item, e_id, stockOneHalfX, stockTwoX, stockThreeX, stockFourX, stockOneX, numBasket, basket_id } });
      } else {
        navigate('/confirmenrty', { state: { selectedStocks, item, e_id, stockOneHalfX, stockTwoX, stockThreeX, stockFourX, stockOneX, numBasket } });
      }
    } else {
      showToast();
    }
  };

  const showToast = () => {
    alert('Please select 2x, 3x and 4x stocks to proceed');
  };

  const setstockOneHalfXValue = (itemname) => {
    if (stockTwoX === itemname) {
      setStockTwoX(null);
    }
    setStockOneHalfX(itemname);
  };

  let temp = 0;
  const setstockOneXValue = (itemname) => {
    if (stockFourX === itemname) {
      setStockFourX(null);
    } else if (stockThreeX === itemname) setStockThreeX(null);
    else if (stockTwoX === itemname) setStockTwoX(null);

    if (temp < 9) temp += 1;
    setStockOneX(itemname);
  };

  const setstockTwoXValue = (itemname) => {
    if (stockFourX === itemname) {
      setStockFourX(null);
    } else if (stockThreeX === itemname) setStockThreeX(null);
    else if (stockOneX === itemname) setStockOneX(null);

    if (temp < 9) temp += 2;
    setStockTwoX(itemname);
  };

  const setstockThreeXValue = (itemname) => {
    if (stockTwoX === itemname) {
      setStockTwoX(null);
    } else if (stockFourX === itemname) setStockFourX(null);
    else if (stockOneX === itemname) setStockOneX(null);

    if (temp < 9) temp += 3;
    setStockThreeX(itemname);
  };

  const setstockFourXValue = (itemname) => {
    if (stockTwoX === itemname) {
      setStockTwoX(null);
    } else if (stockThreeX === itemname) setStockThreeX(null);
    else if (stockOneX === itemname) setStockOneX(null);

    if (temp < 9) temp += 4;
    setStockFourX(itemname);
  };

  const [sliderValues, setSliderValues] = useState([null, null, null]);
  const sum = sliderValues.reduce((acc, val) => acc + val, 0);
  const [disabledSliders, setDisabledSliders] = useState([1, 1, 1]);
  const [maxValues, setMaxValues] = useState([4, 4, 4]);

  const handleSliderChange = (index, newValue) => {
    const updatedValues = [...sliderValues];
    updatedValues[index] = newValue;
    setSliderValues(updatedValues);
  };

  const getLabelValues = () => {
    const maxValue = 9 - sliderValues.reduce((acc, val) => acc + val, 0);
    const labelValues = Array.from({ length: Math.min(maxValue, 4) }, (_, i) => (i + 1).toString());
    return labelValues;
  };

  const minValue = 1;
  const maxValue = 4;
  const defaultValue = 0;
  const width = '100%';
  const labelValues = ['1x', '2x', '3x', '4x'];

  const [isSlide, setIsSlide] = useState([false, false, false]);

  let a = 0;
  let t = 0;
  let last = 0;
  const handleSliderChange1 = (index, newValue) => {
    const updatedValues = [...sliderValues];
    updatedValues[index] = newValue;

    const sum = updatedValues.reduce((acc, val) => acc + val, 0);
    const numberOfSlidersAdjusted = updatedValues.filter(val => val !== null).length;

    if (numberOfSlidersAdjusted === 2) {
      const remainingSliderIndex = updatedValues.indexOf(null);
      const remainingMaxValue = 9 - sum;
      if (remainingMaxValue > 4) remainingMaxValue = 4;
      last = remainingSliderIndex;

      const updatedMaxValues = [...maxValues];
      updatedMaxValues[remainingSliderIndex] = remainingMaxValue;
      setMaxValues(updatedMaxValues);
    } else if (numberOfSlidersAdjusted < 2) {
      const defaultMaxValue = 4;
      const updatedMaxValues = Array.from({ length: maxValues.length }, () => defaultMaxValue);
      setMaxValues(updatedMaxValues);
    }
    console.log('Card items_111', maxValues + " a:" + numberOfSlidersAdjusted);
    setSliderValues(updatedValues);
  };

  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: 'red',
  });

  return (
    <div className="container">
      <div className="scroll-view">
        {/* <BackTopText title='Create basket' /> */}

        {selectedStocks && (
          <div className="center">
            {selectedStocks.map((item, index) => (
              <div className="inputWithPrefix-contest-small" 
              key={index}>
                <div className="rowView100per-flex">
                <img src={item.image} alt={item.symbol} className="app-crypto-image" />

                  <div className="appColumn">
                    <p className="appTextColor12Start">{item.name}</p>
                    <p className="appTextColor12Start">Rs {item.price}</p>
                  </div>
                  <div className="app-rowView">
                    <p className="appTextColor12End" style={{ color: item.percentChange > 0 ? 'green' : 'red' }}>{item.percentChange} % 1hr</p>
                  </div>
                  <div className="rowView50per">
                    <div
                    className="appBgColor10-pointer"
                      onClick={() => setstockOneXValue(item.symbol)}
                      style={{padding:5, borderRadius:10,
                       
                         backgroundColor: stockOneX === item.symbol && stockThreeX !== item.symbol && stockFourX !== item.symbol && stockTwoX !== item.symbol ? 'red' : 'green' }}
                    >1X</div>
                    <div
                    className="appBgColor10-pointer"
                      onClick={() => setstockTwoXValue(item.symbol)}
                      style={{ padding:5, borderRadius:10,
                        backgroundColor: stockTwoX === item.symbol && stockThreeX !== item.symbol && stockFourX !== item.symbol && stockOneX !== item.symbol ? 'red' : 'green' }}
                    >2X</div>
                    <div
                    className="appBgColor10-pointer"
                      onClick={() => setstockThreeXValue(item.symbol)}
                      style={{ padding:5, borderRadius:10,
                        backgroundColor: stockThreeX === item.symbol && stockTwoX !== item.symbol && stockFourX !== item.symbol && stockOneX !== item.symbol ? 'red' : 'green' }}
                    >3X</div>
                    <div
                    className="appBgColor10-pointer"
                      onClick={() => setstockFourXValue(item.symbol)}
                      style={{ padding:5, borderRadius:10,
                        // fontSize:12,
                        backgroundColor: stockFourX === item.symbol && stockTwoX !== item.symbol && stockThreeX !== item.symbol && stockOneX !== item.symbol ? 'red' : 'green' }}
                    >4X</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="summary">
          <p className="appTextColor10">Total x must be 10</p>
        </div>
        <div className="center" style={{marginTop:20}}>
        <div className="inputWithPrefix2  appPadding10"
          onClick={() => {
            checkIfBasketExists();
          }}
          disabled={!(stockTwoX && stockThreeX && stockFourX)}
          style={{color:"black",
           backgroundColor: stockTwoX && stockThreeX && stockFourX ? 'green' : 'grey' }}
        >Save basket</div>
        </div>
        {loading && <LoadingScreen />}

        {/* {loading && (
          <div className="loading">
            <div className="spinner"></div>
            <p>{loadingTxt}</p>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BasketListScreen;
