// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { getDatabase } from 'firebase/database';
import { getFirestore, doc, getDoc,getDocs, setDoc, updateDoc, addDoc, collection, FieldValue } from "firebase/firestore";
import { getDatabase, ref, set, get } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2lG3c89HwZs_0jpjx0F2b1MffY_olxpw",
  authDomain: "tradingclash-a6d81.firebaseapp.com",
  databaseURL: "https://tradingclash-a6d81-default-rtdb.firebaseio.com",
  projectId: "tradingclash-a6d81",
  storageBucket: "tradingclash-a6d81.appspot.com",
  messagingSenderId: "574096806453",
  appId: "1:574096806453:web:7e9b9795c92d433da3d77c",
  measurementId: "G-P19NQYMP9Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
// const db = getFirestore(app);

// Initialize Auth
const auth = getAuth(app);
const database = getDatabase(app);
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const db = getFirestore(app);
// const database = getDatabase(app);

// export { auth,  RecaptchaVerifier, signInWithPhoneNumber, db, database, FieldValue, doc, getDoc,getDocs, setDoc, updateDoc, addDoc, collection, ref, set, get };
// export { db, auth };
export { auth, RecaptchaVerifier, signInWithPhoneNumber , database};
