import React, { useState, useEffect } from 'react';
import '../view/CryptoMarketGainerLoser.css'; // Import the CSS file

const CryptoMarketGainerLoser = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const [cryptoLosers, setCryptoLosers] = useState([]);
  const [cryptoGainers, setCryptoGainers] = useState([]);
  const [sortType, setSortType] = useState(3);

  const fetchCryptoData = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/coins/top_gainers_losers?vs_currency=inr&duration=1h&top_coins=500',
        {
          headers: {
            'User-Agent': 'tradingclash/1.0',
            accept: 'application/json',
            'x-cg-pro-api-key': 'CG-1DrGqL1ynh59Hcpoo1fEsfzG',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.top_losers) {
        const extractedLosers = data.top_losers.map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.inr.toFixed(5),
          image: item.image,
          rank: item.market_cap_rank,
          percentChange: item.inr_1h_change.toFixed(3),
        }));
        setCryptoLosers(extractedLosers);
      }

      if (data.top_gainers) {
        const extractedGainers = data.top_gainers.map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.inr.toFixed(5),
          image: item.image,
          rank: item.market_cap_rank,
          percentChange: item.inr_1h_change.toFixed(3),
        }));
        setCryptoGainers(extractedGainers);
        setCryptoData(extractedGainers); // Default data is set to gainers
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptoData();
  }, []);

  const sortByCryptoFun = (type) => {
    setSortType(type);

    if (type === 3) {
      setCryptoData(cryptoGainers);
    } else if (type === 4) {
      setCryptoData(cryptoLosers);
    }
  };

  return (
    <div className="container-gainer">
      {loading ? (
        <div className="loading-gainer">Loading...</div>
      ) : (
        <>
          {/* <div className=""> */}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>

            <button
            className={` submitButtonTopBar app-margin-10 ${sortType === 3 ? 'active' : ''}`}
              // className={'submitButtonTopBar ${sortType === 3 ? 'active' : ''}'}
              onClick={() => sortByCryptoFun(3)}
            >
              Gainers
            </button>
            <button
              className={` submitButtonTopBar app-margin-10 ${sortType === 4 ? 'active' : ''}`}
              // className={sortType === 4 ? 'active' : ''}
              onClick={() => sortByCryptoFun(4)}
            >
              Losers
            </button>
          </div>

          <div className="crypto-table">
            <div className="crypto-header-gainer crypto-row">
              <span className='appTextColor12'>Rank</span>
              <span className='appTextColor12'>Symbol</span>
              <span className='appTextColor12'>Name</span>
              <span className='appTextColor12'>Price</span>
              <span className='appTextColor12'>% Change</span>
            </div>

            {cryptoData.map((item, index) => (
              <div className="crypto-row" key={index}>
                <span className=' appTextColor12'>{item.rank}</span>
                
                <div className="appTextColor12 center">
                <img src={item.image} alt={item.symbol} className="crypto-image" />
                <span className='center'>{item.symbol.toUpperCase()}</span>
                </div>
                {/* <span className='appTextColor12'>
                  <img src={item.image} alt={item.symbol} className="crypto-image-gainer" />{' '}
                  {item.symbol.toUpperCase()}
                </span> */}
                <span className='appTextColor12'>{item.name}</span>
                <span className='appTextColor12'>{item.price} INR</span>
                <div className="appColumn100 appTextColor10">
                <span
                  className={item.percentChange > 0 ? 'positive' : 'negative'}
                >
                  {item.percentChange}%
                </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CryptoMarketGainerLoser;
