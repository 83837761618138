import React from 'react';
import '../../view/ProgressBar.css'; // Import a CSS file for styling

const ProgressBar = ({ spotsTaken, spotsTotal }) => {
  // Calculate the percentage of spots taken
  const progressPercentage = (spotsTaken / spotsTotal) * 100;

  return (
    <div style={{ marginTop: '5px', marginBottom: '10px', opacity: 0.9, width:'100%' }}>
      {/* Progress container */}
      <div className="progress-container">
        {/* Progress bar */}
        <div
          className="progress-bar"
          style={{
            width: `${progressPercentage}%`,
          }}
        />
      </div>

      {/* Text for spots left */}
      <p 
      className='appTextColor8 greenText'
    //   style={{ fontSize: '12px', color: '#007bff' }}
      >
        Spots left {100 - spotsTaken}
      </p>
    </div>
  );
};

export default ProgressBar;
