import React from "react";
import { NavLink,Link } from "react-router-dom";
import '../../view/TopBar.css'; // Ensure your CSS is properly imported

const MarketTopBar = () => {
  return (
    <div className="center">
      {/* <nav> */}
      <div className="app-rowView">

          <div>
            {/* <NavLink 
              to="marketlist" 
              className="nav-link" 
              activeclassname="active"
            > */}
              <Link to="/market/marketlist" className=" appPrimaryColor10start">

              <span className="marketTopBar ">Markets</span>
            </Link>
          </div>  

          <div>
            {/* <NavLink 
              to="markettrending" 
              className="nav-link" 
              activeclassname="active"
            > */}
              <Link to="/market/markettrending" className="appPrimaryColor10start ">

              <span className="marketTopBar app-margin-10 ">Trending</span>
              {/* bot-text-12 */}
            </Link>
          </div>

          <div>
            {/* <NavLink 
              to="marketgainerloser" 
              className="nav-link" 
              activeclassname="active"
            > */}
              <Link to="/market/marketgainerloser" className="appPrimaryColor10start">
              <span className="marketTopBar ">Gainers/Losers</span>
            </Link>
          </div> 

      </div>

      {/* </nav> */}
    </div>
  );
};

export default MarketTopBar;
