import { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit,where, getDocs } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage,deleteObject } from "firebase/storage";

// import '../view/Login.css'
import { useNavigate } from 'react-router-dom';
import { setItem, getItem, removeItem } from './globals/LocalStorageUtils';
import { useAuth } from './globals/AuthProvider';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, push, set ,onValue,remove} from "firebase/database";
import LoadingScreen from './globals/LoadingScreen';
import imageCompression from 'browser-image-compression';

const db = getFirestore();
const database = getDatabase();

function ChatGlobal() {
    const [upcoming, setUpcoming] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
    const storage = getStorage();

    const { currentUser } = useAuth();
    const auth = getAuth();
    let authUser = null;
    if(auth)
     authUser = auth.currentUser;

    const gotoEventScreen = (c_id, title, entryList, contests) => {
        navigate('/entry', { state: { c_id, title, entryList, contests } });
    };


    useEffect(() => {
    // setItem('exampleData', 'my_test_data');
    // const storedData = getItem('exampleData');
    // console.log(authUser.uid);
    setLoading(true);
    console.log('stred_data '+ auth.currentUser);
    if (authUser) {
        fetchUpcomingSessions();
    } else {
        navigate('/login');
    }
    }, []);

    const fetchUpcomingSessions1 = async () => {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, 'Contests'), 
            orderBy('datedAt', 'asc') , 
            where('isActive', '==', 0),
            limit(12))
          );
  
          const upcomingData = querySnapshot.docs.map((doc) => {
            const item = doc.data();
            const c_id = doc.id;
            const timestamp = Number(item.datedAt);
            const date = new Date(timestamp * 1000);
  
            const now = new Date().getTime();
            const difference1 = timestamp - now;
            const difference = difference1 - 53 * 60;
  
            const day = date.getDate();
            const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
            const dated_at = `${day}, ${monthAbbreviation}`;
  
            return { ...item, c_id, dated_at, difference };
          });
  
          setUpcoming(upcomingData);
          console.log(JSON.stringify(upcomingData));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
      const [selectedImage, setSelectedImage] = useState(null);
      const [message, setMessage] = useState("");
      const [messages, setMessages] = useState([]);

  const fetchUpcomingSessions11 = async () => {
    const messagesRef = ref(database, 'GlobalChat');
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const parsedMessages = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setMessages(parsedMessages);
    });

    // setLoading(false);

  }; 

  const fetchUpcomingSessions = async () => {
    const messagesRef = ref(database, 'GlobalChat');
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const parsedMessages = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setMessages(parsedMessages);
    });
  
    setLoading(false);
  };

  const handleSendMessage1 = async () => {
    // setLoading(true);

    if (message.trim() === "") return;
    const senderId= authUser.uid;
    const senderName =localStorage.getItem('userName');
    const messagesRef = ref(database, 'GlobalChat');
    const newMessageRef = push(messagesRef);
    const newMessage = {
      senderId,
      senderName,
      createdAt: Date.now(),
      type: 1,
      msg: message
    };

    try {
      await set(newMessageRef, newMessage);
      setMessage(""); // Clear the input field after sending the message
      // setLoading(false);
    } catch (error) {
      console.error("Error sending message: ", error);
      // setLoading(false);
    }
  };
  
  const handleSendMessage2 = async () => {
    // setLoading(true);
  
    if (message.trim() === "" && !selectedImage) return;
  
    const senderId = authUser.uid;
    const senderName = localStorage.getItem('userName');
    const messagesRef = ref(database, 'GlobalChat');
    const newMessageRef = push(messagesRef);
  
    let imageUrl = null;
  
    if (selectedImage) {
      const imageStorageRef = storageRef(storage, `global_chat_imgs/${Date.now()}_${selectedImage.name}`);
      const snapshot = await uploadBytes(imageStorageRef, selectedImage);
      imageUrl = await getDownloadURL(snapshot.ref);
    }
  
    const newMessage = {
      senderId,
      senderName,
      createdAt: Date.now(),
      type: selectedImage ? 2 : 1, // 1 for text, 2 for image
      msg: message,
      imageUrl: imageUrl || null, // Store image URL if present
    };
  
    try {
      await set(newMessageRef, newMessage);
      setMessage(""); // Clear the input field
      setSelectedImage(null); // Clear the selected image
      // setLoading(false);
    } catch (error) {
      console.error("Error sending message: ", error);
      // setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    setLoading(true);
  
    if (message.trim() === "" && !selectedImage) return;
  
    const senderId = authUser.uid;
    const senderName = localStorage.getItem('userName');
    const messagesRef = ref(database, 'GlobalChat');
    const newMessageRef = push(messagesRef);
  
    let imageUrl = null;
  
    try {
      if (selectedImage) {
        // Define compression options
        const options = {
          
          // maxSizeMB: 1, // Maximum size in MB
          // maxWidthOrHeight: 1920, // Max width or height of the image
          // useWebWorker: true, // Use web workers to avoid blocking the UI thread

          maxSizeMB: 0.2, // Maximum size in MB (400 KB)
          // maxWidth: 800,  
          // maxHeight: 1200,
  maxWidth: 1152, // Maximum width of the image
  maxHeight: 1920, // Maximum height of the image
  useWebWorker: true, // Use web workers to avoid blocking the UI thread

        };
  
        // Compress the image
        const compressedImage = await imageCompression(selectedImage, options);
  
        // Upload the compressed image to Firebase Storage
        const imageStorageRef = storageRef(storage, `global_chat_imgs/${Date.now()}_${compressedImage.name}`);
        const snapshot = await uploadBytes(imageStorageRef, compressedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      }
  
      // Prepare the message object
      const newMessage = {
        senderId,
        senderName,
        createdAt: Date.now(),
        type: selectedImage ? 2 : 1, // 1 for text, 2 for image
        msg: message,
        imageUrl: imageUrl || null, // Store image URL if present
      };
  
      // Save the message to the database
      await set(newMessageRef, newMessage);
  
      // Clear the input and image after sending the message
      setMessage(""); // Clear the input field
      setSelectedImage(null); // Clear the selected image
      setLoading(false);
    } catch (error) {
      console.error("Error sending message: ", error);
      setLoading(false);
    }
  };

  const handleMsgDel = async (msgId, imageUrl) => {
    try {
      // Remove the message from the database
      const messageRef = ref(database, `GlobalChat/${msgId}`);
      await remove(messageRef);
  
      // If the message has an associated image, remove it from Firebase Storage
      if (imageUrl) {
        const imageRef = storageRef(storage, imageUrl); // Use the image URL to get a reference to the storage object
        await deleteObject(imageRef);
      }
  
      console.log(`Message with ID ${msgId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting message: ", error);
    }
  };

  const setTimeToDate = (timestamp) =>{
    const date = new Date(timestamp );
    const day = date.getDate();
    const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
    const time = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const dated_at = `${day}, ${monthAbbreviation} ${time}`;
    return dated_at;
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
  
    // Calculate the difference in milliseconds
    const diffMs = now - date;
  
    // Convert milliseconds to various units of time
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);  // Approximate months
    const diffYears = Math.floor(diffDays / 365);  // Approximate years
  
    // Return the appropriate human-readable time difference
    if (diffSeconds < 60) {
      return 'just now';
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    } else if (diffDays < 30) {
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else if (diffMonths < 12) {
      return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    } else {
      return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    }
  };
  
  const [imgLoaded, setImgLoaded] = useState(false);

  
    return (
      <div className="wrapper ">
      <div className="container">

      <div className="chat-container">
      <div className="messages-container">
        {messages.map(msg => (
          <div key={msg.id} className={`message ${msg.senderId === authUser.uid ? 'my-message' : 'other-message'}`}>
            {/* <div className="message-sender">{msg.senderName}</div> */}
            {msg.type === 1 && <div className="message-content">{msg.msg}</div>}
            {/* {msg.type === 2 && <img src={msg.imageUrl} alt="sent" className="message-image" />} */}
            
      {msg.type === 2 &&
      <div>
      <img
        src={msg.imageUrl}
        alt="sent"
        className={`message-image ${imgLoaded ? 'visible' : 'hidden'}`}
        onLoad={() => setImgLoaded(true)}
      /> 
      {!imgLoaded && (
        <div className="message-placeholder" />
      )}
      </div>
      }
            {/* <div className="message-content">{msg.msg}</div> */}
            {authUser && 
             authUser.uid=='RrGCS9dEL1d1WBYq0K2CJhM0TzJ2' &&
              <div className='message-delete'
                   onClick={() => handleMsgDel(msg.id, msg.imageUrl)}
                   >
                    delete
              </div>
            } 
            <div className="message-timestamp">{formatTimestamp(msg.createdAt)}</div>
            {/* <div className="message-timestamp">{new Date(msg.createdAt).toLocaleString()}</div> */}
          </div>
        ))}
      </div>

      {authUser && 
      authUser.uid=='RrGCS9dEL1d1WBYq0K2CJhM0TzJ2' &&
      
      <div 
        className=" button-fixed-100  appPadding10 "
        // className=" nextButton" 
        >
         <div className='rowView100per'> 
         
    <div className="input-container ">
    
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message"
        className="chat-input"
      />

      
     
      <button onClick={handleSendMessage} className="chat-send-button">
        Send
      </button>
    </div>

    <input
        type="file"
        accept="image/*"
        onChange={(e) => setSelectedImage(e.target.files[0])}
        className="image-input"
      />

    </div>

      </div>
      }

    {/* { loading && <LoadingScreen/>} */}
      </div>

      </div>
      {loading && <LoadingScreen/>}

      </div>
    );
  }
  
  export default ChatGlobal;