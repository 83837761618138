// LeaderboardScreen.js
import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
import { useLocation,useNavigate } from 'react-router-dom';

// import {
//   collection, getDocs, query, orderBy
// } from "./firebase";
import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// import Loader from "./Loader";
// import BackTopText from "./BackTopText";
// import "./LeaderboardScreen.css";
import LoadingScreen from "./globals/LoadingScreen";

const LeaderboardScreen = () => {
  const [loading, setLoading] = useState(false);
  const [baskets, setBaskets] = useState([]);
  const [userBaskets, setUserBaskets] = useState([]);
  const [cryptoTop, setCryptoTop] = useState([]);
  const [cryptoBottom, setCryptoBottom] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  
  const { c_id } = location.state;

  const db = getFirestore();
  const auth = getAuth();
  const authUser = auth.currentUser;

  useEffect(() => {
    fetchData();
    // fetchCryptoData();
  }, [c_id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const basketsRef = collection(db, 'ActiveNow', c_id, 'Basket');
      const q = query(basketsRef, orderBy('rank'));

      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    //   const userId = "your_user_id"; // Replace with actual user ID
      const userData = data.filter((item) => item.userid === authUser.uid);

      setBaskets(data);
      setUserBaskets(userData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchCryptoData = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=inr&order=market_cap_desc&per_page=20&page=1&sparkline=false&price_change_percentage=1h&x_cg_pro_api_key=YOUR_API_KEY'
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const extractedData = data.map(item => ({
        id: item.id,
        symbol: item.symbol,
        name: item.name,
        price: item.current_price,
        image: item.image,
        percentChange: parseFloat(item.price_change_percentage_1h_in_currency.toFixed(3)),
      }));

      const sortedData = [...extractedData].sort((a, b) => b.percentChange - a.percentChange);

      const top5 = sortedData.slice(0, 5);
      const bottom5 = sortedData.slice(-5);

      setCryptoTop(top5);
      setCryptoBottom(bottom5);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const gotoBasketDetailScreen = (item) => {
    const baskType='1';
    const e_id =c_id;
    const basket_id= item.id;

    // navigate(`/basketdetail/${item.id}`, { state: { item, c_id } });
    navigate('/basketdetail', { state: { item,e_id,basket_id,baskType } });
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
    // fetchCryptoData();
  };

  return (
    <div className="app-container">
      {/* <BackTopText title="Leaderboard" /> */}

      {/* <div className="crypto-container">
        <h2>Top 5 Cryptocurrencies (1 Hour)</h2>
        <div className="crypto-row">
          {cryptoTop.map((crypto, index) => (
            <div key={index} className="crypto-item">
              <img src={crypto.image} alt={crypto.name} className="crypto-image" />
              <p>{crypto.name}</p>
              <p className={crypto.price > 0 ? "crypto-price green" : "crypto-price red"}>Rs {crypto.price}</p>
              <p className={crypto.percentChange > 0 ? "crypto-change green" : "crypto-change red"}>{crypto.percentChange}%</p>
            </div>
          ))}
        </div>

        <h2>Bottom 5 Cryptocurrencies (1 Hour)</h2>
        <div className="crypto-row">
          {cryptoBottom.map((crypto, index) => (
            <div key={index} className="crypto-item">
              <img src={crypto.image} alt={crypto.name} className="crypto-image" />
              <p>{crypto.name}</p>
              <p className={crypto.price > 0 ? "crypto-price green" : "crypto-price red"}>Rs {crypto.price}</p>
              <p className={crypto.percentChange > 0 ? "crypto-change green" : "crypto-change red"}>{crypto.percentChange}%</p>
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className="contest-details">
        <button onClick={gotoBasketDetailScreen}>Contest Details</button>
      </div> */}

      {/* <div className="refresh-container">
        <p>Swipe to refresh (points update every 1 min)</p>
        <button onClick={onRefresh}>Refresh</button>
      </div> */}

      <div className="app-container">
      <h2 className="textColor12 textCenter bold italic">Leaderboard</h2>

        <div className="leaderboard-item">
          <span className="appTextBg10">Rank</span>
          <span className="appTextBg10">All Baskets ({baskets.length})</span>
          <span className="appTextBg10">Points</span>
        </div>

        {userBaskets.length > 0 && (
          <div className="my-teams ">
            <h3 className="appPrimaryColor10flex" >My teams</h3>
            {userBaskets.map((item, index) => (
              <div key={index} className="leaderboard-item" onClick={() => gotoBasketDetailScreen(item)}>
                <span className="appTextColor12flex">#{item.rank}</span>
                <div className=" appTextColor12flex leaderboard-item-details">
                  <img src={item.image || require('../assets/applogo.png')} 
                  alt={item.userName} 
                  className="app-crypto-image" />
                <div className="appTextColor12flex container2 center">    
                  <span className="appTextColor12flex">{item.userName}</span>
                  {item.amountWon  && (
                 <span className="appTextColor12flex appGreen12">Won {item.amountWon.toFixed(2)}</span>
                 )}
                </div>
                  <span className="appTextColor12flex">{item.basketNo ? `T ${item.basketNo + 1}` : "T 1"}</span>
                </div>
                <div className="appTextColor12flex leaderboard-item-points">
                  <span className={item.totalPoints > 0 ? "green" : "red"}>{item.totalPoints}</span>
                  <span className={item.totalPerChange > 0 ? "green" : "red"}>{item.totalPerChange ? `${item.totalPerChange.toFixed(2)}%` : "0 %"}</span>
                </div>
              </div>
            ))}
          </div>
        )}

       <h3 className="appPrimaryColor10flex" style={{marginTop:20}} >All teams</h3>
        {baskets.map((item, index) => (
          <div key={index} className="leaderboard-item " onClick={() => gotoBasketDetailScreen(item)}>
            <span className="appTextColor12flex">#{item.rank}</span>
            <div className="appTextColor12flex leaderboard-item-details">
            <img src={item.image || require('../assets/applogo.png')} 
                  alt={item.userName} 
                  className="app-crypto-image" />
              <div className="appTextColor12flex container2 center">    
              <span className="appTextColor12flex">{item.userName}</span>
              {item.amountWon  && (
                <span className="appTextColor12flex appGreen12">Won {item.amountWon.toFixed(2)}</span>
              )}
              </div>
              <span className="appTextColor12flex">{item.basketNo ? `T ${item.basketNo + 1}` : "T 1"}</span>
            </div>
            <div className="appTextColor12flex leaderboard-item-points">
              <span className={item.totalPoints > 0 ? "green" : "red"}>{item.totalPoints}</span>
              <span className={item.totalPerChange > 0 ? "green" : "red"}>{item.totalPerChange ? `${item.totalPerChange.toFixed(2)}%` : "0 %"}</span>
            </div>
          </div>
        ))}
      </div>
      {loading && <LoadingScreen />}

      {/* {loading && <Loader loading={loading} text="Loading ranks..." />} */}
    </div>
  );
};

export default LeaderboardScreen;
