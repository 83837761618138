import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import MarketTopBar from "./globals/MarketTopBar"; // Adjust the path if necessary

const CryptoMarketTabs = () => {
  return (
    <div>
      {/* Render the top bar as tabs */}
      <MarketTopBar />

      {/* Render the nested routes */}
      <div className="market-content">
        <Outlet /> {/* This will render the selected tab's content */}
      </div>
    </div>
  );
};

export default CryptoMarketTabs;
