// LoadingScreen.js
import React from 'react';
// import { useLoading } from './LoadingContext';
// import './LoadingScreen.css';
import '../../view/Loading.css'
// const LoadingScreen = () => {
// //   const { loading } = useLoading();

//   if (!loading) return null;

//   return (
//     <div className="loading-overlay">
//       <div className="loading-spinner"></div>
//     </div>
//   );
// };
const LoadingScreen = () => {

  const splitTextIntoSpans = (text) => {
    // Replace regular spaces with &nbsp; for correct display
    const textWithNbsp = text.replace(/ /g, '\u00A0');
    return textWithNbsp.split('').map((letter, index) => (
      <span key={index} className="letter" style={{ animationDelay: `${index * 0.1}s` }}>
        {letter}
      </span>
    ));
  };
  
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
        <p className='loading-overlay-text'> 
        {/* Loading ...  */}
        {splitTextIntoSpans('Loading please wait...')}
        </p>
      </div>
    );
  };

export default LoadingScreen;
