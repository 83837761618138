// src/CustomAlert.js
import React, { useEffect, useRef } from 'react';
import '../../view/CustomAlert.css'; // We will create this file for styling

const CustomAlert = ({ message, onClose }) => {
  const alertRef = useRef();

  const handleClickOutside = (event) => {
    if (alertRef.current && !alertRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-alert">
      <div className="custom-alert-content">
        <div className='appBgColor10'>{message}</div>
        <div className='custom-alert-close' onClick={onClose}>Close</div>
      </div>
    </div>
  );
};

export default CustomAlert;
