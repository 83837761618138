import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  collection, addDoc, getFirestore,
  getDocs,getDoc, setDoc, updateDoc, doc,
  arrayUnion, arrayRemove, query, orderBy, onSnapshot
} from 'firebase/firestore';
import '../view/MarketDataScreen.css'; // Ensure the CSS file is imported
import { getDatabase, ref,push, set } from 'firebase/database';
import { database } from '../firebaseconfig'; // Update the path based on your file structure
import LoadingScreen from './globals/LoadingScreen';
import EditUserProfile from './EditUserProfile';
import { ReactComponent as Appname} from '../assets/appname.svg';

// const firebaseConfig = {
//   // Your Firebase configuration
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

const MarketDataScreen = () => {
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [cryptoTop, setCryptoTop] = useState([]);
  const [cryptoBottom, setCryptoBottom] = useState([]);
  const [cryptoVolume, setCryptoVolume] = useState([]);
  const [cryptoTrending, setCryptoTrending] = useState([]);
  const [email, setEmail] = useState('');

  const now = Math.floor(new Date().getTime());
  const formattedDate = new Date(now).toLocaleDateString('en-US', {
    month: 'long', // Full month name (e.g., January)
    day: 'numeric', // Day of the month, numeric (e.g., 1)
    year: 'numeric', // Full year (e.g., 2024)
  });

  const auth = getAuth();
  const db = getFirestore();
  const authUser = auth.currentUser;

  useEffect(() => {

    if (authUser) {
    const userName = localStorage.getItem('userName');
    if(!userName)
    fetchUserData();
    }

    fetchCryptoData();
    fetchCryptoDataVolume();
    fetchCryptoDataTrend();
  }, []);

  const fetchUserData = async() =>{
    try {
      const userDocRef = doc(collection(db, 'users'), authUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const docData = userDocSnapshot.data();
        localStorage.setItem('userName', ''+docData.userName);
         localStorage.setItem('name', ''+docData.name);
        localStorage.setItem('userImg', ''+docData.userImg);
        // setTotalContests(docData.totalContests || 0);
      } else {
        console.log('No such document!');
        editProfile();

      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailsRef = ref(database, 'WebEmails');
    const newEmailRef = push(emailsRef); // Generate a unique key

    try {
      await set(newEmailRef, {
        email: email,
        createdAt: new Date().toISOString()
      });

      alert("Thank You for Signing Up!\n\nYou’ve successfully joined the waitlist for early access to our beta fantasy gaming platform. Keep an eye on your inbox for your invitation and further updates. We're excited to have you on board!");

      // alert('Email saved successfully');
      setEmail(''); // Clear the input field
    } catch (error) {
      alert('Error saving email: ' + error.message);
    }
  };

  const fetchCryptoData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/coins/top_gainers_losers?vs_currency=usd&duration=24h&top_coins=500',
        {
          headers: {
            'User-Agent': 'tradingclashweb/1.0',
            'Access-Control-Allow-Origin':'*',
            accept: 'application/json',
            'x-cg-pro-api-key': 'CG-1DrGqL1ynh59Hcpoo1fEsfzG'
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data && data.top_losers) {
        const extractedDataLosers = data.top_losers.slice(0, 10).map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.usd.toFixed(7),
          image: item.image,
          rank: item.market_cap_rank,
          percentChange: item.usd_24h_change.toFixed(3),
        }));
        setCryptoBottom(extractedDataLosers);
      }

      if (data && data.top_gainers) {
        const extractedDataGainers = data.top_gainers.slice(0, 10).map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.usd.toFixed(7),
          image: item.image,
          rank: item.market_cap_rank,
          percentChange: item.usd_24h_change.toFixed(3),
        }));
        setCryptoTop(extractedDataGainers);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
      setLoading(false);
    }
  };

  const fetchCryptoDataVolume = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=30&page=1&sparkline=false&price_change_percentage=24h&x_cg_pro_api_key=CG-1DrGqL1ynh59Hcpoo1fEsfzG',
        {
          headers: {
            'User-Agent': 'tradingclashweb/1.0',
            'Access-Control-Allow-Origin':'*',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const arr = ['usdt', 'usdc', 'fdusd','vnst', 'dai'];

      const extractedData = data
        .filter(item => !arr.includes(item.symbol))
        .slice(0, 10)
        .map(item => ({
          id: item.id,
          symbol: item.symbol,
          name: item.name,
          price: item.current_price,
          image: item.image,
          totalVolume: item.total_volume,
          rank: item.market_cap_rank,
          percentChange24h: item.price_change_percentage_24h.toFixed(3),
        }));

      setCryptoVolume(extractedData);
      setLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
      setLoading(false);
      setRefreshing(false);
    }
  };

  const fetchCryptoDataTrend = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://pro-api.coingecko.com/api/v3/search/trending',
        {
          headers: {
            'User-Agent': 'tradingclashweb/1.0',
            'Access-Control-Allow-Origin':'*',
            accept: 'application/json',
            'x-cg-pro-api-key': 'CG-1DrGqL1ynh59Hcpoo1fEsfzG'
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      const extractedData = data.coins.slice(0,10).map(item => ({
        id: item.item.id,
        symbol: item.item.symbol,
        name: item.item.name,
        price: item.item.data.price.toFixed(7),
        image: item.item.thumb,
        rank: item.item.market_cap_rank,
        percentChange24h: item.item.data.price_change_percentage_24h.inr.toFixed(3),
      }));

      setCryptoTrending(extractedData);
      setLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching crypto data:', error.message);
      setLoading(false);
      setRefreshing(false);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchCryptoDataVolume();
    fetchCryptoData();
    fetchCryptoDataTrend();
  };

  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // Set to true to open on navigate

  const closeLoginPopup = () => {
    setLoginPopupOpen(true);
  };
  const editProfile = () => {
    setLoginPopupOpen(true);
  };

  return (
    <div className="wrapper">

   {isLoginPopupOpen && <EditUserProfile isOpen={isLoginPopupOpen} onClose={closeLoginPopup} isCancelable={true}/>}

    <div className="container">
      <div className="">

            <div  className=" center">
            {/* <div className="appLogoImageContainer col center"> */}
            <p className="textColor12 textCenter bold italic" style={{ fontSize: 16 }}>
              Dashboard
            </p>
                  {/* <img
                    className="circularImageMedium2"
                    src={require('../assets/applogo.png')}
                    alt={crypto.name}
                  /> */}
            {/* </div>   */}
            </div>    
        <div>
          <p className="textColor12 textCenter bold" style={{ fontSize: 16 }}>
            {formattedDate}
          </p>
          <p className="textColor12 textCenter bold italic" style={{ fontSize: 16 }}>
            Top 500 # Market cap
          </p>

          <p className="textBgSecondaryBold12">Top Gainers in the Last 24 Hours</p>
          <div className="row">
            {cryptoTop.map((crypto, index) => (
              <div key={index} className="col center">
                <div className="circularImageContainerMedium">
                  <img
                    className="circularImageMedium2"
                    src={crypto.image ? crypto.image : 'https://via.placeholder.com/150'}
                    alt={crypto.name}
                  />
                </div>
                <div className="column">
                  <p className="textBg10Width">{crypto.name}</p>
                  <p className="textBg8Width white"># {crypto.rank}</p>
                  <p className="textBg8Width white">USD {crypto.price}</p>
                  <p className={`textBg8Width ${crypto.percentChange > 0 ? 'green' : 'red'}`}>
                    {crypto.percentChange} %
                  </p>
                </div>
              </div>
            ))}
          </div>

          <p className="textBgSecondaryBold12 marginTop20">Top Losers in the Last 24 Hours</p>
          <div className="row">
            {cryptoBottom.map((crypto, index) => (
              <div key={index} className="col center">
                <div className="circularImageContainerMedium">
                  <img
                    className="circularImageMedium2"
                    src={crypto.image ? crypto.image : 'https://via.placeholder.com/150'}
                    alt={crypto.name}
                  />
                </div>
                <div className="column">
                  <p className="textBg10Width">{crypto.name}</p>
                  <p className="textBg8Width white"># {crypto.rank}</p>
                  <p className="textBg8Width white">USD {crypto.price}</p>
                  <p className={`textBg8Width ${crypto.percentChange > 0 ? 'green' : 'red'}`}>
                    {crypto.percentChange} %
                  </p>
                </div>
              </div>
            ))}
          </div>

          <p className="textBgSecondaryBold12 marginTop20">Highest Volumes in the Last 24 Hours</p>
          <div className="row">
            {cryptoVolume.map((crypto, index) => (
              <div key={index} className="col center">
                <div className="circularImageContainerMedium">
                  <img
                    className="circularImageMedium2"
                    src={crypto.image ? crypto.image : 'https://via.placeholder.com/150'}
                    alt={crypto.name}
                  />
                </div>
                <div className="column">
                  <p className="textBg10Width">{crypto.name}</p>
                  <p className="textBg8Width white"># {crypto.rank}</p>
                  <p className="textBg8Width white">USD {crypto.price}</p>
                  <p className={`textBg8Width ${crypto.percentChange24h > 0 ? 'green' : 'red'}`}>
                    {crypto.percentChange24h} %
                  </p>
                </div>
              </div>
            ))}
          </div>

          <p className="textSecondaryBold12 marginTop20">Top Trending in the Last 24 Hours</p>
          <div className="row">
            {cryptoTrending.map((crypto, index) => (
              <div key={index} className="col center">
                <div className="circularImageContainerMedium">
                  <img
                    className="circularImageMedium2"
                    src={crypto.image ? crypto.image : 'https://via.placeholder.com/150'}
                    alt={crypto.name}
                  />
                </div>
                <div className="column">
                  <p className="textBg10Width">{crypto.name}</p>
                  <p className="textBg8Width white"># {crypto.rank}</p>
                  <p className="textBg8Width white">USD {crypto.price}</p>
                  <p className={`textBg8Width ${crypto.percentChange24h > 0 ? 'green' : 'red'}`}>
                    {crypto.percentChange24h} %
                  </p>
                </div>
              </div>
            ))}
          </div>
        
        </div>

        {/* <div  className="rowView">
          <p className="textSecondaryBold12 marginTop20" > Beta Testers Wanted: Sign Up for Exclusive Access to Our Fantasy Gaming Platform</p>
          <form onSubmit={handleSubmit}>
          <input
          type="email"
          placeholder="Enter email"
          className="inputText"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
         <button type="submit" className="submitButton">Submit</button>
         </form>
        </div>   */}

        <div  className="col center">
            <div className="appNameImageContainer col center">
            <div  
            className='center'
            // className="circularImageMedium2"
            >
             <Appname 
             style={{ width: '250px', height: '200px',alignContent:'center' ,marginRight:'10px'}}
              />
            </div>
                  {/* <img
                    className="circularImageMedium2"
                    src={require('../assets/appname.png')}
                    alt={crypto.name}
                  /> */}
            </div>  
            </div>

      </div>
       
      {loading && <LoadingScreen />}

      {/* </div> */}
      {/* {loading && <div className="loading">
        </div>} */}
    </div>

    </div>
  );
};

export default MarketDataScreen;
