import React, { useState, useEffect } from "react";

import { Navigate, Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

const PrivateRoute = ({auth}) => {
  
  // const auth = true; // Replace with actual authentication logic

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
