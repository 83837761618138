// src/Login.js
import React, { useState } from 'react';
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from '../firebaseconfig';
// import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import  '../view/Login.css';
import LoadingScreen from './globals/LoadingScreen';
import { getFirestore, collection, getDocs,getDoc,doc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { setDoc, addDoc } from "firebase/firestore";

import { useHistory } from 'react-router-dom';

// import gLogin form './';
import googlelogin from '../assets/googlelogin.png';
import EditUserProfile from './EditUserProfile';

function Login() {
    // const history = useHistory();
    const navigate = useNavigate();
    // const history = useHistory();
    const db = getFirestore();

    const navigateToProfile = () => {
      navigate('/contests'); // Navigate to the '/profile' route
    };

    const googleProvider = new GoogleAuthProvider();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading]= useState(false);

  // const [otpSent, setOtpSent] = useState(false);

//   const auth = getAuth();

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved - allow signInWithPhoneNumber.
        console.log('Recaptcha resolved');
      }
    });
  };

  const handleSendOtp = (e) => {
    console.log('User not authenticated.'+e);

    setLoading(true);
    e.preventDefault();
    setupRecaptcha();
    const phoneNumberWithPrefix = '+91' + phoneNumber;

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumberWithPrefix, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setOtpSent(true);
        console.log('OTP sent');
        setLoading(false);
      }).catch((error) => {
        alert('Error : ' + error.message);
        console.log(error);
        setLoading(false);
      });
  };

  const handleVerifyOtp = async (e) => {
    setLoading(true);
    e.preventDefault();
    
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp);
        console.log('User signed in successfully:', result.user);
        localStorage.setItem('isLoggedin', '0');

        await checkUserProfile('num', null, null); // Ensure checkUserProfile is awaited
        // navigate('/'); // Navigate after profile check
        
      } catch (error) {
        alert('Error: ' + error.message);
        console.log(error);
      } finally {
        setLoading(false); // Ensure loading state is reset in both success and error cases
      }
    }
  };

  // const handleVerifyOtp11 = async(e) => {
  //   setLoading(true);
  //   e.preventDefault();
  //   if (confirmationResult) {
  //     confirmationResult.confirm(otp).then((result) => {
  //       console.log('User signed in successfully:', result.user);
        
  //       // history.push('/market'); // Assuming '/market' is the path to MarketListScreen
  //       navigate('/'); 
  //       await checkUserProfile('num',null,null); 
  //       setLoading(false);
  //   }).catch((error) => {
  //     alert('Error : ' + error.message);
  //       console.log(error);
  //       setLoading(false);

  //     });
  //   }
  // };

  const checkUserProfile = async (loginType,displayName,email) => {
    try {
      const authUser = auth.currentUser;
      if (!authUser) {
        console.log('User not authenticated.');
        return;
      }

      const userDocRef = doc(collection(db, 'users'), authUser.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      console.log('if user_exist', userDocSnapshot.exists, '--', userDocSnapshot.data());

      localStorage.setItem('loginType', loginType);

      const signupType = '1';

      if (userDocSnapshot.exists()) {
        // User exists in Firestore
        const docData = userDocSnapshot.data();
        localStorage.setItem('name', docData.name);
        localStorage.setItem('userName', docData.userName);
        localStorage.setItem('email', docData.email);
        // localStorage.setItem('isLogin', '2');

        if (docData.userImg) {
          localStorage.setItem('profileImg', docData.userImg);
        }

        localStorage.setItem('isLoggedin', '1');
        navigate('/'); // Navigate after profile check
        // navigate('/bottom-tab-navigator'); // Adjust according to your route
      } else {
        // User doesn't exist in Firestore
        localStorage.setItem('isLoggedin', '2');
        setLoginPopupOpen(true);
        // navigate('/editprofile'); // Navigate after profile check
        // navigate('/user-profile-signup', { state: { signupType, displayName, email } }); // Adjust according to your route
      }
    } catch (error) {
      console.error('Error checking user profile:', error);
    }
  };

  const editProfile1 = () => {
    navigate('/editprofile');
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User Info:", result.user);
      if(result && result.user){
        localStorage.setItem('isLoggedin', '0');

        await checkUserProfile('num', null, null); // Ensure checkUserProfile is awaited  
      }  
      // setLoading(false); // Ensure loading state is reset in both success and error cases   
      // Handle successful login, e.g., update state or navigate
    } catch (error) {
      console.error("Error during Google login:", error);
      // setLoading(false); // Ensure loading state is reset in both success and error cases
    } finally {
      setLoading(false); // Ensure loading state is reset in both success and error cases
    }
  };

  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // Set to true to open on navigate

  const closeLoginPopup = () => {
    setLoginPopupOpen(true);
  };
  const editProfile = () => {
    setLoginPopupOpen(true);
  };

  return (
<div className="wrapperLogin">

    <div className="containerLogin">
    {/* <div  className="col center"> */}
    {isLoginPopupOpen && <EditUserProfile isOpen={isLoginPopupOpen} onClose={closeLoginPopup} isCancelable={false} />}

    {!otpSent ? (
      <div  className="containerLogin" 
      // onSubmit={handleSendOtp}
      >

<h2 className="textColor12 textCenter bold font-fam-serif">Login</h2>

      <div className="inputWithPrefix">
        <p >+91</p>
      <input
      type="tel" 
      inputMode="numeric" 
      
          // type="numeric"
          // inputMode="numeric"
          placeholder="Phone Number"
          className="inputTextLogin"
          value={phoneNumber}
          // onChange={(e) => {
          //   const newValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
          //   setPhoneNumber(newValue);
          // }}
          onChange={(e) => setPhoneNumber(e.target.value)}
          maxLength='10'
          required
        />
        </div>

        <div className='submitButton top-margin-20'
        onClick={handleSendOtp}
        //  type="submit"
         type="button"
         >Send OTP</div>

<h6 className="textColor12 textCenter bold " 
    style={{marginTop:'40px', marginBottom:'40px'}} >Or</h6>

    <div >
    
    <div className='submitButton' onClick={handleGoogleLogin}>
      
    <img src={googlelogin} 
          className="google-login-icon" />
      {/* <p className=""> */}
      Login with Google
        {/* </p>     */}
      </div>
    </div>
    
      </div>
    ) : (
      <div className="containerLogin" 
      // onSubmit={handleVerifyOtp}
      >
            <h2 className="textColor12 textCenter bold font-fam-serif">Verify OTP</h2>

        <input
         type="tel" 
         inputMode="numeric" 
          // type="numeric"
          className="inputTextOtp"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="OTP"
          maxLength='6'
          required
        />
        <div className='submitButton'
        //  type="submit"
         type="button"
        onClick={handleVerifyOtp}
        >Verify OTP</div>
      </div>
    )}


    <div id="recaptcha-container"></div>
    {/* </div> */}
    

    </div>


    {loading && <LoadingScreen />}

  </div>

  );
}

export default Login;
