import { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit,where, getDocs,getDoc,doc } from 'firebase/firestore';

import '../view/Login.css'
import { useNavigate } from 'react-router-dom';
import { setItem, getItem, removeItem } from './globals/LocalStorageUtils';
import { useAuth } from './globals/AuthProvider';
import { getAuth } from "firebase/auth";
import LoadingScreen from './globals/LoadingScreen';
import { getDatabase, ref, get } from "firebase/database";
import CountdownTimer from './globals/CountdownTimer';
import ProgressBar from './globals/ProgressBar';

const db = getFirestore();

function UpcomingSessions() {
    const [upcoming, setUpcoming] = useState([]);
    const [ongoing, setOngoing] = useState([]);
    const [loading, setLoading] = useState(false);

    const [myContests, setMyContests] = useState([]);
   const [myBaskets, setMyBaskets] = useState([]);

    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const auth = getAuth();
    const dbrt = getDatabase();

    // const getMycontestDetails= async (c_id) => {
    //     const contest_ToGo = upcoming.filter(item => item.c_id === c_id);
    //     // console.log('contest_ToGo'+JSON.stringify(contestToGo));
    //     if(contest_ToGo && contest_ToGo.length>0){
    //      const contestToGo=contest_ToGo[0];
    //       // console.log('contest_ToGo'+contestToGo.isActive+'--'+contestToGo.entryList);
    //      gotoEventScreen(contestToGo.c_id, contestToGo.title, contestToGo.entryList, contestToGo);
        
    //     }else
    //     Alert.alert('Sorry!!', 'This contest has been started !!! ');
      
        
    //   }
    

    const gotoLeaderboardScreen = (c_id, title, entryList, contests) => {
      navigate('/leaderboard', { state: { c_id } });
    };

    const authUser = auth.currentUser;

    useEffect(() => {
    // setItem('exampleData', 'my_test_data');
    // const storedData = getItem('exampleData');
    console.log('stred_data '+ auth.currentUser);
    setLoading(true);
    if (authUser) {
      const userName = localStorage.getItem('userName');

      const isLogin = localStorage.getItem('isLogin');

      // if(!userName && isLogin=='1')
      // fetchUserData();
      fetchMyActiveContests();
      fetchUpcomingSessions();
    } else {
        navigate('/login');
        setLoading(false);
    }
    }, []);

    const fetchUserData = async() =>{
      try {
        const userDocRef = doc(collection(db, 'users'), authUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const docData = userDocSnapshot.data();
          localStorage.setItem('userName', ''+docData.userName);
           localStorage.setItem('name', ''+docData.name);
          localStorage.setItem('userImg', ''+docData.userImg);
          // setTotalContests(docData.totalContests || 0);
        } else {
          console.log('No such document!');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
  
    };

    const fetchUpcomingSessions1 = async () => {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, 'Contests'), 
            orderBy('datedAt', 'asc') , 
            where('isActive', '==', 0),
            limit(12))
          );
  
          const upcomingData = querySnapshot.docs.map((doc) => {
            const item = doc.data();
            const c_id = doc.id;
            const timestamp = Number(item.datedAt);
            const date = new Date(timestamp * 1000);
  
            const now = new Date().getTime();
            const difference1 = timestamp - now;
            const difference = difference1 - 53 * 60;

            // const timestamp = Number(item.datedAt);
            // const date = new Date(timestamp*1000);
            // const difference1 = timestamp - now;
            // const difference = (difference1) - 53 * 60;

  
            const day = date.getDate();
            const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
            const dated_at = `${day}, ${monthAbbreviation}`;
  
            return { ...item, c_id, dated_at, difference };
          });
  
          setUpcoming(upcomingData);
          // console.log(JSON.stringify(upcomingData));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };

      const now = Math.floor(new Date().getTime() / 1000);

      const fetchUpcomingSessions = async () => {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, 'Contests'), 
            orderBy('datedAt', 'asc') , 
            limit(12)
            )
          );
      
          const upcomingData = [];
          const ongoingData = [];
      
          querySnapshot.docs.forEach((doc) => {
            const item = doc.data();
            const c_id = doc.id;
            const timestamp = Number(item.datedAt);
            const date = new Date(timestamp * 1000);
      
            // const now = new Date().getTime();
            const difference1 = timestamp  - now;
            const difference = difference1 - 53 * 60;
            
      

            const day = date.getDate();
            const monthAbbreviation = date.toLocaleString('en-US', { month: 'short' });
            const dated_at = `${day}, ${monthAbbreviation}`;
            console.log('diff_erence',timestamp+' - '+dated_at);

            const formattedItem = { ...item, c_id, dated_at, difference };
      
            if (item.isActive === 0) {
              upcomingData.push(formattedItem);
            } else if (item.isActive === 1) {
              ongoingData.push(formattedItem);
            }
          });
      
          setUpcoming(upcomingData);
          setOngoing(ongoingData);
          console.log('Upcoming Data:', JSON.stringify(upcomingData));
          console.log('Ongoing Data:', JSON.stringify(ongoingData));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
      
      const fetchMyActiveContests = async () => {
        setLoading(true);
        // const db = getDatabase();
        const user = auth.currentUser;
        const userId = user.uid;

        const contestRef = ref(dbrt, `UserContest/${userId}`);
    
        try {
          const snapshot = await get(contestRef);
    
          if (snapshot.exists()) {
            const allData = [];
            const uniqueData = [];
    
            snapshot.forEach((contestSnapshot) => {
              const contestId = contestSnapshot.key;
              uniqueData.push({ contestId: contestId, ...contestSnapshot.val() });
    
              contestSnapshot.child('Baskets').forEach((basketSnapshot) => {
                allData.push({ id: basketSnapshot.key, contestId: contestId, ...basketSnapshot.val() });
              });
            });
    
            setMyContests(uniqueData);
            console.log("Data_1011"+JSON.stringify(uniqueData));

            setMyBaskets(allData);
          } else {
            console.log("No data available");
            setMyContests([]);
            setMyBaskets([]);
          }
          setLoading(false);

        } catch (error) {
          console.error("Error getting data:", error);
          setLoading(false);

        }
    
      };
  
      const getMyContestDetails = (contestId) => {
        // Implement the function to handle contest details
        console.log('Contest ID:', contestId);
      };
    
      const convertTimeToDateFormat = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      };
    
      // const convertTo12HourFormat = (timeString) => {
      //   const date = new Date(timeString);
      //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      // };
      const convertTo12HourFormat = (timeString) => {        
        if (timeString === '0900am') {
          return '09:00 am - 10:00 am';
        }
      
        const hour = parseInt(timeString, 10);
      
        if (isNaN(hour) || hour < 0 || hour > 24) {
          return 'Invalid time';
        }
      
        if (hour === 0 || hour === 24) {
          return '12:00 am - 12:30 am';
        } else if (hour === 12) {
          return '12:00 pm - 12:30 pm';
        } else if (hour > 12) {
          return `${hour - 12}:00 pm - ${hour - 12}:30 pm`;
        } else {
          return `${hour}:00 am - ${hour}:30 am`;
        }
      };

      const getMycontestDetails= async (c_id) => {
        const contest_ToGo = upcoming.filter(item => item.c_id === c_id);
        // console.log('contest_ToGo'+JSON.stringify(contestToGo));
        if(contest_ToGo && contest_ToGo.length>0){
         const contestToGo=contest_ToGo[0];
          // console.log('contest_ToGo'+contestToGo.isActive+'--'+contestToGo.entryList);
         gotoEventScreen(contestToGo.c_id, contestToGo.title, contestToGo.entryList, contestToGo);
        
        }else
        alert('Sorry!!', 'This contest has been started !!! ');
      
        
      }

      const removeDataItem = (index, item) => {
        upcoming[index].isActive=2;
        console.log('remove_ind',''+upcoming[index].spotsTaken)
      console.log('remove_items',''+item.startAt+'--'+item.endAt)
        // setUpcoming((prevUpcoming) => prevUpcoming.filter((_, i) => i !== index));
      };

      const checkIfContestIsActive = async (c_id) => {
        const db = getFirestore(); // Get Firestore instance
        const c_ids = "" + c_id.toString();
    
        try {
          const docRef = doc(db, 'Contests', c_ids); // Reference to the document
          const docSnapshot = await getDoc(docRef); // Get document snapshot
          
          if (docSnapshot.exists()) {
            const docData = docSnapshot.data();
            console.log('before_isactive ' + docData.isActive + ' --' + c_ids);
            // Return true if isActive is equal to 0, otherwise return false
            return docData.isActive === 0;
          } else {
            // Document with the provided ID does not exist
            return false;
          }
        } catch (error) {
          console.error('An error occurred while checking if contest is active:', error);
          throw error; // Rethrow the error to be handled by the caller
        }
      };
      const gotoEventScreen11= (c_id, title, entryList, contests) => {
        navigate('/entry', { state: { c_id, title, entryList, contests } });
     };
    
  
      const gotoEventScreen = async (c_id, title, entryList, contest) => {
        try {
          const isActive = await checkIfContestIsActive(c_id);
          const spotsLeft = contest.spotsTotal > 0;
      
          console.log('after_isactive'+isActive+'--'+contest.isActive)
          // if(!isActive){
          if(contest.isActive==2 || !isActive){
            const item=contest;
            gotoLeaderboardScreen(c_id, title, entryList, item,isActive);
          }
          else{
      
            if (true && spotsLeft && isActive) {
              const contests= contest;
              navigate('/entry', { state: { c_id, title, entryList, contests } });
              // navigation.navigate('EntryScreen', { c_id, title, entryList, contest });
            } else {
              if (!isActive) {
                alert('Sorry!!', 'This contest has been started ');
              }
              else if (!spotsLeft) {
                alert('Sorry!!', 'All the spots have been filled ');
              } else {
                // showToast();
              }
            }
          }
          
        } catch (error) {
          // Handle the error here
          console.error('An error occurred while checking if contest is active:', error);
          // Optionally, you can show an error message to the user
          alert('Error', 'An error occurred while checking if contest is active. Please try again later.');
        }
      };
      
    return (
      <div className="container">

     <div className='center'>
      {myContests && myContests.length > 0 && (
        <div style={{ alignItems: 'center', marginLeft: 20, marginTop: 10, marginBottom: 10 , width:'70%'}}>
          {/* <h3></h3> */}
          <h2 className="textColor12 textCenter bold italic">My Contests</h2>

          <div 
          className='rowView-scroll'
          >
            {myContests.map((item, index) => (  
                <div           
                 className="col center inputWithPrefix-mycontest"
                 onClick={() => getMycontestDetails(item.contestId )}
                 >
                  <div style={{ marginBottom: 5 }}>
                    <span style={{ color: 'green' , fontSize:'10px'}}>Joined</span>
                  </div>
                  <div 
                  className="appTextColor10"
                  >
                    <span>{convertTimeToDateFormat(item.createdAt)}</span>
                  </div>
                  <div
                  className="appTextColor12"
                  >
                    <strong>{convertTo12HourFormat(item.contestId)}</strong>
                  </div>
                </div>
              // </button>
            ))}
          </div>
        </div>
      )}
     </div>

    {currentUser ? (
      
     <div className="container center">
{ ongoing && ongoing.length>0 &&
<div className='col center'>
<h2 className="textColor12 textCenter bold italic">Ongoing Contests</h2>
        {ongoing.map((item, index) => (
          <div key={index} 
          className="col center inputWithPrefix-contest"
          onClick={() => gotoLeaderboardScreen(item.c_id, item.title, item.entryList, item )}
          style={{ cursor: 'pointer' }}>
            <div  >
              {/* Countdown Timer */}
              {/* <CountdownTimer difference={item.difference} onHide={() => removeDataItem(index, item)} /> */}
              {/* <div> */}
              <p className="textBg10Width">{item.title}</p></div>
              <div className='rowViewContests'>
                <div style={{ alignContent:'flex-start', textAlign:'start'}}>
                <p className="textBg10WidthStart">{item.startAt}</p>
                </div>
                <div>
                <p className="textBg10Width">{item.endAt}</p>
                </div>
              </div>
              <div><p className="textBg10Width">{item.dated_at}</p></div>
              {/* Progress Bar */}
              <div style={{ marginTop: 5, marginBottom: 10, opacity: 0.5 }}>
                {/* Replace with your progress bar component */}
                {/* <Progress.Bar progress={item.spotsTaken / item.spotsTotal} width={320} height={3} color={colors.appgreen} borderWidth={0} unfilledColor={colors.secondary} /> */}
                <div><p className="textBg10Width">Spots left: {100 - item.spotsTaken}</p></div>
              {/* </div> */}
            </div>
          </div>
        ))}

  </div>      
    }       
        
     <h2 className="textColor12 textCenter bold italic">Upcoming Contests</h2>
        {upcoming.map((item, index) => {
          return (
            <div key={index}
              className=" col center inputWithPrefix-contest marginTop20"
              onClick={() => gotoEventScreen(item.c_id, item.title, item.entryList, item)}
              style={{ cursor: 'pointer' }}>
              <div>
                {/* Countdown Timer */}
                {/* <CountdownTimer difference={item.difference} 
                onHide={() => removeDataItem(index, item)} /> */}
                {/* <div> */}
                <div
                  key={index}
                  style={{
                    // alignContent:'center',
                    // alignItems:'center',
                    // alignSelf:'center',
                    // justifyContent:'center'
                    // margin: '5px',
                    // padding: '10px', 
                    // border: '1px solid #ccc' 
                  }}
                >
                  <CountdownTimer 
                  difference={item.difference} 
                  onHide={() => removeDataItem(index, item)}
                  // onHide={() => console.log('Countdown ended')}
                   />
                </div>
                {/* <p className="textBg10Width">{item.title}</p> */}
                
                


                </div>

                <div className='rowViewContests'>

                <div style={{ alignContent: 'flex-start', textAlign: 'start' }}>
                  <p className="textBg12Width">{item.title}</p>
                </div>
                <div>
                  <p className="textBg12Width">{item.dated_at}</p>
                </div>
                
                </div>

              <div className='rowViewContests'>
                <div style={{ alignContent: 'flex-start', textAlign: 'start' }}>
                  <p className="textBg12Width bold">{item.startAt}</p>
                </div>
                <div>
                  <p className="textBg12Width bold">{item.endAt}</p>
                </div>
              </div>
              {/* <div><p className="textBg10Width">{item.dated_at}</p></div> */}
              {/* Progress Bar */}
              <div style={{ marginTop: 5, marginBottom: 10, opacity: 0.5 }}>
                <div>
                  <ProgressBar spotsTaken={item.spotsTaken} spotsTotal={item.spotsTotal} />
                </div>
                {/* Replace with your progress bar component */}
                {/* <Progress.Bar progress={item.spotsTaken / item.spotsTotal} width={320} height={3} color={colors.appgreen} borderWidth={0} unfilledColor={colors.secondary} /> */}
                {/* <div><p className="textBg10Width">Spots left: {100 - item.spotsTaken}</p></div> */}
                {/* </div> */}
              </div>
            </div>
          );
        })}

     
      </div>  
        ) : (
            <h1 className="textColor12 textCenter bold italic">Please log in</h1>
          )}

      {loading && <LoadingScreen />}

      </div>
    );
  }
  
  export default UpcomingSessions;