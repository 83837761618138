import { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit, getDocs,doc,getDoc } from 'firebase/firestore';

import { useLocation,useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

import '../view/Login.css'
import LoadingScreen from './globals/LoadingScreen';

const db = getFirestore();

function EntryAdmin() {
    const [upcoming, setUpcoming] = useState([]);
  
    const [loading, setLoading] = useState(true);
  const [contest, setContest] = useState(null);
  const [data, setData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const { c_id, title, entryList, contests } = location.state;


  const fetchDataFromFirestore = async () => {
    setLoading(true);
    console.log('00--11--' + entryList);
    try {
      const data = [];

      if (entryList && entryList.length > 0) {
        for (const docId of entryList) {
          const userDocRef = doc(collection(db, 'Entries'), '' + docId);
          // const docSnapshot = await getDocs(userDocRef);
          const docSnapshot = await getDoc(userDocRef);

          const docData = docSnapshot.data();
          console.log('1111' + docData);
          const e_id = docSnapshot.id;
          data.push({ ...docData, e_id });
        }
        setData(data);
      } else {
        console.log('entryList is not defined or empty.');
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data from Firestore:', error);
      setLoading(false);
    }
  };

  const userListsData = async () => {

    setLoading(true);
    try{

      const basketSnapshot = await getDocs(collection(db, 'ActiveNow', c_id,'Basket'));
      const data = [];
      basketSnapshot.forEach((doc) => {
        const item= doc.data();
        const id = doc.id;

        data.push({...item,id});
      })
      setUserList(data);
    }
    catch(error){
      console.error('Error fetching data:', error);
    }
    finally {
      setLoading(false);
      setRefreshing(false);
    }
    // Implement your user list fetching logic here
  };

  const gotoStockScreen = async (e_id, item) => {
    navigate('/cyptoselect', { state: { e_id, item } });
  };

  const authUser = auth.currentUser;

  const gotoBasketDetailScreen = (item) => {
    
    if(item.userid==authUser.uid){

    const baskType='1';
    const e_id =c_id;
    const basket_id= item.id;

    // navigate(`/basketdetail/${item.id}`, { state: { item, c_id } });
    navigate('/basketdetail', { state: { item,e_id,basket_id,baskType } });
    }
  };
  
  useEffect(() => {
    console.log('entryList__ '+JSON.stringify(contests));

    fetchDataFromFirestore();
    userListsData();
  }, [entryList]);

  
    return (
      <div className="container">
      <div className="safe-area">
        {/* <BackTopText title={title} /> */}

        <div className="center">

          <div className='inputWithPrefix-entry'>
          {contests && (
            <div className="col center ">
                <span className="appTextBg10">{contests.title}</span>
                <span className="appTextBg10">{contests.dated_at}</span>
                <div className='rowViewContests'>
                <div>  
                <span className="appTextBg10">{contests.startAt}</span>
                </div>
                <div>
                <span className="appTextBg10">{contests.endAt}</span>
                </div>
                </div>

            </div>
          )}

{/* <div className=" appBgContainer "> */}

          <div className="">
            {data.map((item, index) => (
              // <div className='appBgContainer'>
              <div key={index} 
              >
                {/* <div className="rowView100per"> */}
                  {/* <span className="textBig">Rs {item.entry}</span> */}
                  <div className="rowView">
                    <span className="appTextBg10">spots left  </span>
                    <span className="appTextBg10">{100 - contests.spotsTaken}</span>
                  </div>
                <div
                 onClick={() => gotoStockScreen(item.e_id, item)}
               className=" rowView100per  appBgContainer">
                <span className="inputWithPrefix center appPadding10 ">Entry Rs {item.entry}</span>
                </div>
              </div>
            ))}
          </div>

          </div>

          <div className="appColumn100-flex center app-margin-20">
            {userList && userList.length > 0 ? (
              <div className='center appColumn100'>
                <div>
                <p className="appTextBg10">players in the pot: {userList.length}</p>
                </div>
                <div className=' center appColumn1002'>
                {userList.map((item, index) => (
                  <div 
                  key={index}
                   onClick={() => gotoBasketDetailScreen(item)} 
                   className="rowView100per">
                   
                    {/* <div className="app-cryptoItem"> */}
                    <div className="inputWithPrefix-contest-small">
                    <img src={
                      // item.image
                    //  || 
                     require('../assets/applogo.png')} 
                  alt={item.userName} 
                  className="app-crypto-image" />
                      <div className="appTextColor14">{item.userName ? item.userName : 'Not available'}</div>
                      {/* <div className="small-card"> */}
                      <div className="appTextColor14">{item.basketNo ? 'T ' + (item.basketNo + 1) : 'T 1'}</div>
                      {/* </div> */}
                    </div>
                  </div>
                ))}
                </div>
              </div>
            ) : (
              <div className="centered-text-container">
                <span className="appTextBg10">No users have participated in this contest yet</span>
              </div>
            )}
          </div>
        </div>
      </div>
      { loading && <LoadingScreen/>}
      {/* <Loader loading={loading} text="Loading please wait..." /> */}
    </div>
    );
  }
  
  export default EntryAdmin;